import { Radio, Space } from 'antd';
import React, { useEffect } from 'react';

function ModePopup(props) {
    const ref = React.useRef<any>(null);
    const { onClickOutside, ageandNumber, setAgeandNumber } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <div ref={ref} className="mode-popup">
            <div className="mode-category">
                <div className="mode-category-title">Age</div>
                <Radio.Group onChange={(e) => { setAgeandNumber({ ...ageandNumber, age: e.target.value }) }} value={ageandNumber.age}>
                    <Space direction="vertical">
                        {['Early', 'Mid Age', 'Late'].map((item) => <Radio value={item}>{item}</Radio>)}
                    </Space>
                </Radio.Group>
            </div>
            <div className="divider"></div>
            <div className="mode-category">
                <Radio.Group onChange={(e) => { setAgeandNumber({ ...ageandNumber, number: e.target.value }) }} value={ageandNumber.number}>
                    <div className="mode-category-title">Numbers</div>
                    <Space direction="vertical">
                        {['20s', '30s', '40s', '50s'].map((item) => <Radio value={item}>{item}</Radio>)}
                    </Space>
                </Radio.Group>
            </div>
        </div>
    )
}

export default ModePopup;