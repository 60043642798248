import React,{FC,useMemo,useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import { HyperProjectFoldersState, UPLOAD_MULTIPLE_FILES_SUCCESS } from '../../../store/types';
import { setSelectedImageLayerId,setSelectedTextLayerId,setSelectedSizeId,setSelectedImageId,setSelectedTextId} from '../../../store/actions';
import {useHistory} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './index.scss';
import { Button, Checkbox, Drawer, Input, TabPane, Tabs } from 'pyxis-ui-kit';

import { ReactComponent as iconMasterLayer} from '../../../assets/svg/iconMasterLayer.svg';
import { ReactComponent as iconChildVariant} from '../../../assets/svg/iconChildVariant.svg';
import iconSizeVariant from '../../../assets/svg/iconSizeVariant.svg';
import iconImageVariant from '../../../assets/svg/iconImageVariant.svg';
import iconTextVariant from '../../../assets/svg/iconTextVariant.svg';
import Icon, { Loading3QuartersOutlined } from '@ant-design/icons';
import Img from '../../ImageLoader/ImageLoader';
import { difference,union,isEmpty } from 'lodash';


interface filterDrawerInterface{
    isDrawerVisible:boolean,
    setIsDrawerVisible:Function,
    sizeTabsArr:any
}

interface TextVariantInterface{
  layersWithAssets:any,
  selectedTextLayer:any,
  dispatch:Function,
  setMultipleTextId:Function,
  multipleTextId:any
}

interface ImageVariantInterface{
  layersWithAssets:any,
  selectedImageLayer:any,
  dispatch:Function,
  setMultipleImageId:Function,
  multipleImageId:any
}

interface SizeVariantInterface{
  dispatch:Function,
  selectedSizeId:number[],
  hyperProjectData:any,
  selectedSize:any,
  setMultipleSizeId:Function,
  multipleSizeId:number[],
  sizeTabsArr:any,
  uniqueCategory:string[],
  selectedSizeCategory:any,
  setSelectedSizeCategory:Function
}

interface VariantHeaderInterface{
  setMultipleSizeId?:Function,
  selectedTextLayer?:any,
  setMultipleTextId?:Function,
  selectedImageLayer?:any,
  setMultipleImageId?:Function,
  type:string,
  hyperProjectData?:any,
  layersWithAssets?:any,
  multipleSizeId?:any,
  multipleTextId?:any,
  multipleImageId?:any
}

const VariantHeader:FC<VariantHeaderInterface>=({type,hyperProjectData,layersWithAssets,setMultipleSizeId,selectedImageLayer
  ,selectedTextLayer,setMultipleImageId,setMultipleTextId,multipleSizeId,multipleTextId,multipleImageId})=>{
  const isAllSizeSelected=type==="size"?hyperProjectData?.sizes?.length===multipleSizeId?.length:false
  const [layerTextIds,setLayerTextIds]=useState<any>([])
  const [layerImageIds,setLayerImageIds]=useState<any>([])
  let isAllTextOfCurrentLayerSelected=(multipleTextId?.length!==0&&layerTextIds?.length!==0)?difference(layerTextIds,multipleTextId).length===0:false
  let isAllImageOfCurrentLayerSelected=(multipleImageId?.length!==0&&layerImageIds?.length!==0)?difference(layerImageIds,multipleImageId).length===0:false
  const isSelected=(type==="size"&&isAllSizeSelected)||(type==="text"&&isAllTextOfCurrentLayerSelected)||(type==="image"&&isAllImageOfCurrentLayerSelected)

  useEffect(()=>{
    setLayerTextIds(selectedTextLayer?.assets.reduce((acc: number[], val: any) => [...acc, val.id], []))
  },[selectedTextLayer])

  useEffect(()=>{
    setLayerImageIds(selectedImageLayer?.assets.reduce((acc: number[], val: any) => [...acc, val.id], []))
  },[selectedImageLayer])

  useEffect(()=>{
    isAllTextOfCurrentLayerSelected=(multipleTextId?.length!==0&&layerTextIds?.length!==0)?difference(layerTextIds,multipleTextId).length===0:false
  },[multipleTextId])

  useEffect(()=>{
    isAllImageOfCurrentLayerSelected=(multipleImageId?.length!==0&&layerImageIds?.length!==0)?difference(layerImageIds,multipleImageId).length===0:false
  },[multipleImageId])
 
  return <div className="text-header">
    <div className="title">
      <img src={type==="text"?iconTextVariant:type==="image"?iconImageVariant:iconSizeVariant} className="icon-variant"/>
      <span className="variant-title">{type} Layer’s Variants 
      {
        (multipleSizeId?.length>0||multipleImageId?.length>0||multipleTextId?.length>0)&&
        ` (${type==="size"?multipleSizeId?.length:type==="image"?multipleImageId?.length:multipleTextId?.length} ${type} selected)`
      }
      </span>
    </div>
    <div className="selection">
        <Checkbox 
          checked={isSelected}
          className="select-checkbox"
          onChange={()=>{
          if(type==="text"){
            if(isAllTextOfCurrentLayerSelected){
              setMultipleTextId((textIds:any)=>textIds.filter((textId:any)=>!layerTextIds.includes(textId)))
            }else{
              setMultipleTextId((textIds:any)=>union([...textIds,...layerTextIds]))
            }
          } else if(type==="image"){
            if(isAllImageOfCurrentLayerSelected){
              setMultipleImageId((imageIds:any)=>imageIds.filter((imageId:any)=>!layerImageIds.includes(imageId)))
            }else{
              setMultipleImageId((imageIds:any)=>[...imageIds,...layerImageIds])
            }
          } else if (type==="size"){
            if(isAllSizeSelected){
              setMultipleSizeId([])
            }else{
              let sizeIds = hyperProjectData?.sizes.reduce((acc: number[], val: any) => [...acc, val.id], []);
              setMultipleSizeId(sizeIds)
            }
          }
        }}/> {isSelected?"Deselect all":"Select all"}
    </div>
  </div>
}

const TextVariant:FC<TextVariantInterface>=({layersWithAssets,selectedTextLayer,dispatch,setMultipleTextId,multipleTextId})=>{
  // const [tempTextArray, setTempTextArray] = useState<any>({});

  // const checkIfChecked=(assetId:number)=>{
  //   let newOne=Object.keys(tempTextArray).map(function(keyName, keyIndex) {
  //     console.log(tempTextArray[keyName],tempTextArray[keyName].indexOf(assetId)>-1)
  //     return tempTextArray[keyName].indexOf(assetId)>-1
  //   })
  //   console.log(newOne,"newOne")
  //   return false
    
  // }
  // console.log(Object.values(tempTextArray).indexof('22228'))
  return <div className="variants">
  <Tabs
    defaultActiveKey={selectedTextLayer?.id?.toString()}
    onChange={(key:string)=>{
      dispatch(setSelectedTextLayerId(parseInt(key)))
    }}
  >
      {(layersWithAssets?.results||[]).map((layer:any,index:number)=>(
        (layer.type==="Text") && (layer.assets.length>1) && <>
          <TabPane tab={layer.name} key={layer.id} className="filter-tab">
              {(selectedTextLayer?.assets||[]).map((asset:any,index:number)=>(
                    <div className="text-container">
                      <div className="text-layer">
                          <div className="text-info">
                            <span className="icon-container">{asset.is_default ? <Icon className="layer-icon" component={iconMasterLayer}/> : <Icon className="layer-icon" component={iconChildVariant}/>}</span>
                            <span className="layer-title">{asset.is_default ? "Master Layer" : `Variant ${index+1}`}</span>
                          </div>
                          <div className="text-action">
                            <Checkbox checked={(multipleTextId[layer.id]!==undefined&&multipleTextId[layer.id].indexOf(asset.id)>-1)} onChange={()=>{
                              if (multipleTextId[layer.id]!==undefined&&multipleTextId[layer.id].indexOf(asset.id)>-1) {
                                // let newMultipleTextId=multipleTextId.filter((textId)=>textId!==asset.id)
                                // setMultipleTextId(newMultipleTextId)
                                multipleTextId[layer.id]=multipleTextId[layer.id].filter((assetId:number)=>assetId!==asset.id)
                              }else{
                                if(!(layer.id in multipleTextId)) {
                                  multipleTextId[layer.id]=[asset.id]
                                }else{
                                  multipleTextId[layer.id]=(multipleTextId[layer.id].concat(asset.id))
                                }
                                // setMultipleTextId((textIds:any)=>[...textIds,asset.id])
                              }
                              console.log(multipleTextId,"array")
                              }}/>
                          </div>
                      </div>
                      <div className="text-variant">
                        {asset.text}{asset.id} 
                      </div>
                    </div>
              ))}
          </TabPane>
          </>
      ))} 
    </Tabs>
  </div>
}

const ImageVariant:FC<ImageVariantInterface>=({layersWithAssets,selectedImageLayer,dispatch,setMultipleImageId,multipleImageId})=>{
  return <div className="variants">
  <Tabs
    defaultActiveKey={selectedImageLayer?.id?.toString()}
    onChange={(key:string)=>{
      dispatch(setSelectedImageLayerId(parseInt(key)))
    }}
  >
      {(layersWithAssets?.results||[]).map((layer:any,index:number)=>(
        (layer.type==="Image") && (layer.assets.length>1) &&
          <TabPane tab={layer.name} key={layer.id} className="image-tab">
              {(selectedImageLayer?.assets||[]).map((asset:any,index:number)=>(
                <div className="image-container">
                    <Img src={asset.link.includes("roman3")?asset.link.replace('/upload','/upload/w_260,q_auto'):asset.link+'?q=40&w=260&auto=format'} className="asset-img"/>
                    {asset.id}
                    <span className="icon-container">{asset.is_default ? <Icon className="layer-icon" component={iconMasterLayer}/> : <Icon className="layer-icon" component={iconChildVariant}/>}</span>      
                        {/* <Checkbox className="select-image" checked={multipleImageId&&(multipleImageId.indexOf(asset.id)>-1)} onChange={()=>{
                          if (multipleImageId&&(multipleImageId.indexOf(asset.id)>-1)) {
                            let newMultipleImageId=multipleImageId.filter((textId)=>textId!==asset.id)
                            setMultipleImageId(newMultipleImageId)
                          }else{
                            setMultipleImageId((imageIds:any)=>[...imageIds,asset.id])
                        }}}/> */}
                        <Checkbox className="select-image" checked={(multipleImageId[layer.id]!==undefined&&multipleImageId[layer.id].indexOf(asset.id)>-1)} onChange={()=>{
                          if (multipleImageId[layer.id]!==undefined&&multipleImageId[layer.id].indexOf(asset.id)>-1) {
                            // let newmultipleImageId=multipleImageId.filter((textId)=>textId!==asset.id)
                            // setmultipleImageId(newmultipleImageId)
                            multipleImageId[layer.id]=multipleImageId[layer.id].filter((assetId:number)=>assetId!==asset.id)
                          }else{
                            if(!(layer.id in multipleImageId)) {
                              multipleImageId[layer.id]=[asset.id]
                            }else{
                              multipleImageId[layer.id]=(multipleImageId[layer.id].concat(asset.id))
                            }
                            // setmultipleImageId((textIds:any)=>[...textIds,asset.id])
                          }
                          console.log(multipleImageId,"array")
                        }}/>
                </div>
            ))}
          </TabPane>
      ))} 
    </Tabs>
  </div>
}

const SizeVariant:FC<SizeVariantInterface>=({dispatch,selectedSizeId,hyperProjectData,selectedSize,setMultipleSizeId,multipleSizeId,sizeTabsArr,uniqueCategory,selectedSizeCategory,setSelectedSizeCategory})=>{
  return <div className="variants">
  <Tabs
    defaultActiveKey={selectedSizeId?.toString()}
    onChange={(key:string)=>{
      let sizeCategory=sizeTabsArr.filter((size:any)=>size.category===key)
      setSelectedSizeCategory(sizeCategory)
    }}
  >
    {uniqueCategory.map((category:any,index:number)=>(
     <TabPane className="filter-tab" tab={category} key={category}>
          {selectedSizeCategory && <>
            {(selectedSizeCategory.map((size:any,index:number)=>(
                <div className="text-container">
                  <div className="text-layer">
                      <div className="text-action size-action">
                        <div className="size-name">{size.name}</div>
                        <div className="size">{size.width} * {size.height} px</div>
                      </div>
                      <Checkbox checked={multipleSizeId&&(multipleSizeId.indexOf(size.id)>-1)} onChange={()=>{
                        if (multipleSizeId&&(multipleSizeId.indexOf(size.id)>-1)) {
                          let newMultipleSizeId=multipleSizeId.filter((textId)=>textId!==size.id)
                          setMultipleSizeId(newMultipleSizeId)
                        }else{
                          setMultipleSizeId((imageIds:any)=>[...imageIds,size.id])
                        }}}
                     />
                  </div>
              </div>
          )))}
        </>}
      </TabPane> 
      ))} 
    </Tabs>
  </div>
}

const FilterDrawer:FC<filterDrawerInterface> = ({isDrawerVisible,setIsDrawerVisible,sizeTabsArr}) => {
      const [multipleTextId,setMultipleTextId]=useState<any>({})
      const [multipleImageId,setMultipleImageId]=useState<any>({})
      const [multipleSizeId,setMultipleSizeId]=useState<any>([])
      const [selectedSizeCategory,setSelectedSizeCategory]=useState<any>([])
      const state = useSelector((state: {hyperProject: HyperProjectFoldersState})=>state.hyperProject);
      const history = useHistory();
      const { location: {search} } = history;
      const searchParam = new URLSearchParams(search);
      const banner = searchParam.get("banner");
      const dispatch = useDispatch();
      const { selectedHyperProjects,hyperProjectData,layersWithAssets,selectedImageId,selectedTextId,selectedImageLayerId,selectedTextLayerId,selectedSizeId } = state;
      const selectedImageLayer = useMemo(()=>selectedImageLayerId ? (layersWithAssets?.results||[]).find((l:any)=>l.id === selectedImageLayerId):null,[selectedImageLayerId]);
      const selectedTextLayer = useMemo(()=>selectedTextLayerId ? (layersWithAssets?.results||[]).find((l:any)=>l.id === selectedTextLayerId):null,[selectedTextLayerId]);
      const selectedSize = useMemo(()=>selectedSizeId ? (hyperProjectData?.sizes||[]).find((l:any)=>l.id === selectedSizeId):null,[selectedSizeId]);
      let uniqueCategory:string[]=useMemo(()=>Array.from(new Set(sizeTabsArr.map((item: any) => item.category))),[sizeTabsArr])

      useEffect(()=>{
        // using internal state so that API is hit on only show filter button click
        selectedSizeId&&setMultipleSizeId(selectedSizeId)
        selectedImageId&&setMultipleImageId(selectedImageId)
        selectedTextId&&setMultipleTextId(selectedTextId)
      },[])
      
      useEffect(()=>{
          // Finding first data of Image and text to display in first tab 
          let defaultText=layersWithAssets?.results.find((layer:any)=>layer.type==="Text")
          let defaultImage=layersWithAssets?.results.find((layer:any)=>layer.type==="Image")
          dispatch(setSelectedImageLayerId(defaultImage?.id))
          dispatch(setSelectedTextLayerId(defaultText?.id))
      },[layersWithAssets])

      useEffect(()=>{
        // Finding first data of size to display in first tab 
        let defaultSizeCategory=sizeTabsArr.filter((size:any)=>size.category===uniqueCategory[0])
        setSelectedSizeCategory(defaultSizeCategory)
    },[sizeTabsArr])
      
      return <>
        <Drawer
          placement="top"
          closable={false}
          className="filter-drawer"
          visible={isDrawerVisible}
          onClose={()=>{setIsDrawerVisible(false)}}
          // visible={true}
          footer={
              <div className="filter-footer">
                    <div className="close-container">
                        <Button type="link" onClick={()=>{setIsDrawerVisible(false)}}>
                            Close
                        </Button>
                    </div>
                    <div className="filter-container">
                        <Button className="reset-filter" type="link" onClick={()=>{ 
                          setMultipleSizeId([])
                          setMultipleImageId({})
                          setMultipleTextId({})
                        }}>Reset filters</Button>
                        <Button className="show-results" type="primary" onClick={()=>{
                          dispatch(setSelectedSizeId(multipleSizeId))
                          // console.log(multipleTextId,multipleImageId,"Hello")
                          !isEmpty(multipleTextId)&&dispatch(setSelectedTextId(Object.values(multipleTextId)))
                          !isEmpty(multipleImageId)&&dispatch(setSelectedImageId(Object.values(multipleImageId)))
                          setIsDrawerVisible(false)
                        }}>Show Results</Button>
                    </div>
              </div>
          }
        >
        <div className="filter-header">
            <div className="title">Filters</div>
            <div className="subtitle">Filter variants by {hyperProjectData?.sizes.length>1&&"size"} {hyperProjectData?.sizes.length>1&&(selectedImageLayerId||selectedTextLayerId)&&","} {(selectedImageLayerId||selectedTextLayerId)&&"variant parameters"} to find what you need.</div>
        </div> 
        {/* Display size tab if we have more than 1 size */}
        {/* SIZE VARIANT */}
        {hyperProjectData?.sizes.length>1&&<>
          <VariantHeader type="size" hyperProjectData={hyperProjectData} setMultipleSizeId={setMultipleSizeId} multipleSizeId={multipleSizeId}/> 
          <SizeVariant 
            dispatch={dispatch}
            selectedSizeId={selectedSizeId}
            hyperProjectData={hyperProjectData}
            selectedSize={selectedSize}
            setMultipleSizeId={setMultipleSizeId}
            multipleSizeId={multipleSizeId}
            sizeTabsArr={sizeTabsArr}
            uniqueCategory={uniqueCategory}
            selectedSizeCategory={selectedSizeCategory}
            setSelectedSizeCategory={setSelectedSizeCategory}
          />
        </>}
        {/* Display text tab if we have more than 1 text layer */}
        {/* TEXT VARIANT  */}
        {selectedTextLayer?.assets.length>1&&<>
          <VariantHeader 
            type="text" 
            layersWithAssets={layersWithAssets}
            selectedTextLayer={selectedTextLayer}
            setMultipleTextId={setMultipleTextId}
            multipleTextId={multipleTextId}
          /> 
          <TextVariant 
            dispatch={dispatch}
            layersWithAssets={layersWithAssets}
            selectedTextLayer={selectedTextLayer}
            setMultipleTextId={setMultipleTextId}
            multipleTextId={multipleTextId}
          />
        </>}
        {/* Display image tab if we have more than 1 image layer */}
        {/* Image VARIANT  */}
        {selectedImageLayer?.assets.length>1&&<>
          <VariantHeader 
            type="image" 
            layersWithAssets={layersWithAssets}
            selectedImageLayer={selectedImageLayer}
            setMultipleImageId={setMultipleImageId}
            multipleImageId={multipleImageId}
          /> 
          <ImageVariant 
            dispatch={dispatch}
            layersWithAssets={layersWithAssets}
            selectedImageLayer={selectedImageLayer}
            setMultipleImageId={setMultipleImageId}
            multipleImageId={multipleImageId}
          />
        </>}
        </Drawer>
        
      </>
}

export default FilterDrawer;