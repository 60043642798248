import { cloneDeep, omit, uniqueId } from 'lodash';
import { Drawer, Button, UploadDragger } from 'pyxis-ui-kit';
import { S3_BASE_URL } from '../../../utilities/paths';

import { ReactComponent as uploadIcon } from "../../../assets/svg/upload.svg";
import IconFolder from "../../../assets/svg/iconUploadAccent.svg";
import { ReactComponent as iconFolder } from '../../../assets/svg/iconFolder.svg';

import React, { FC, ReactElement, useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addFolder, createFolderStructure, fetchFolders, fileToProject, setCurrentFolderFile, setProjects, setUploadedBanners, setUploadedFolder, setUploadFileList, setActiveFolder,


    setProjectLoader, setFileToProjectQueue, fileUploader, uploadToS3, projectUpload, updateProject, createProject, uploadFile, uploadVleProof
} from '../../../store/actions';
import { Folder, FolderState, UploadVLEImageProofs } from '../../../store/types';

import { openToast } from '../../../Toasts';
import { detectQueryString, getPreview, getFolderStructure } from '../../../utilities/common-function';
import { store } from '../../../store/store';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import Img from '../../ImageLoader/ImageLoader';
import './index.scss';
//import {  getFileType } from '../../../utilities/common-function';
import async from 'async';
import axios from 'axios';
import { v4 } from 'uuid';

import Icon, { ClockCircleOutlined, CompassOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'pyxis-ui-kit';

import RandomPreview from "../../../assets/img/random_preview.png";
import Approved from "../../../assets/img/successfully-approve.png";
import httpService from '../../../HttpService/HttpService';
import AWS from 'aws-sdk';
import fs from 'fs';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { grey } from '@material-ui/core/colors';
import { withTheme } from 'styled-components';
const TEN_MB = 10*1024*1024;  //10MB


const UploadProofs: FC<{ handleClose: Function; onSubmit: Function, expirationTime: string | null, setProofSubmitted: Function }> = ({ handleClose, onSubmit, expirationTime, setProofSubmitted }) => {
    const [fileList, setFileList] = useState<any[]>([]);
    const [loading, setLoader] = useState(false);
    const [isSizeExceeded,setisSizeExceeded] = useState(false);
    const [uploadFiles,setUploadFiles] = useState([])
    const dispatch = useDispatch();
    const [showSuccess, setShowSuccess] = useState(false);
    const fileUpload = useRef<any>(null);
    const params:any = useParams();
    let vle_ID = JSON.parse(sessionStorage.getItem('vleId'));
    const state = useSelector((state: any) => state);
    const getTokenOrPublic = (state: any) => state.authentication.token || state.publicLink.token;
    let projectURLLink = window.location.href;
    const [loadingFiles, setloadingFiles] = useState(false);
    const [imageSelected, setImageSelected] = useState(false);
    const [nextButtonClick,setNextButtonClick] = useState(false);
    const [ProofImage_outside,setProofImage_outside] = useState<any[]>([]);
    let outside_imageUrl:any,inside_imageUrl:any;
    const [images,setImages] = useState(false);


    const handleChange_insideCentre = (e: any) => {
        setisSizeExceeded(false);
        console.log("handle on change for inside shop image");
        if (!loadingFiles) setloadingFiles(true);
        let files: any[] = e?.target?.files;
        for (let i = 0; i < files.length; i++) {
            files[i].uid = uniqueId("file_");
            files[i].thumbUrl = URL.createObjectURL(files[i]);

            if(files[i].size > TEN_MB){
                setisSizeExceeded(true);
                setImageSelected(false);
                removeFile(files[i].uid);
            }else{
                setFileList((f: any) => ([...f, ...files]));
                setImages(true);
                setImageSelected(true);
            }
        }
    };

    const handleChange_outsideCentre = (e: any) => {
        console.log("handle on change for outside shop image");
        setisSizeExceeded(false);
        if (!loadingFiles) setloadingFiles(true);
        let files_outside: any[] = e?.target?.files;
        for (let i = 0; i < files_outside.length; i++) {
            files_outside[i].uid = uniqueId("file_");
            files_outside[i].thumbUrl = URL.createObjectURL(files_outside[i]);
            if(files_outside[i].size > TEN_MB){
                setisSizeExceeded(true);
                removeFile(files_outside[i].uid);
                console.log("ProofImage_outside",ProofImage_outside);
                setImageSelected(false);
            }else{
                setProofImage_outside((f: any) => ([...f, ...files_outside]));
                setFileList((f: any) => ([...f, ...files_outside]));
                console.log("ProofImage_outside",ProofImage_outside);
                setImageSelected(true);
            }
        }
        
    };

   const handleFileUpload_insideCentre = async (files:any) => {
        console.log("fileList.....",fileList);
        console.log("handleFileUpload length :::::; ",fileList.length)
        // console.log("outside_proof_image:::: in handle file upload",outside_proof_image);
        // console.log("length of outside image proof:::",outside_proof_image);
        const dirName = 'uploadProofs';
        const urlhash = params.projectLinkKey;
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID || '',
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY || '',
          region: 'ap-south-1',
        });
        const uploadedImageUrls = [];
       
        const s3 = new AWS.S3();

        // const handleUploadComplete = () => {
        //     if (uploadCounter === files.length) {
        //         console.log(uploadedImageUrls);
        //         const imageUrlString = uploadedImageUrls.join(",");
        //         updateImageUrls(imageUrlString);
        //     }
        // };
        Array.from(files).forEach((file:any) => {
          const fileName = file?.name;
          const filePath = `${dirName}/${urlhash}/${vle_ID}/${fileName}`;
          const uploadParams = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME || '',
            Key: filePath,
            Body: file,
          };
          s3.upload(uploadParams, (err, data) => {
            if (err) {
              console.error(`Error uploading file ${fileName}:`, err);
            } else {
              console.log(`File ${fileName} uploaded successfully. Location:`, data.Location);
              inside_imageUrl =  data.Location; 
              updateImageUrls(outside_imageUrl,inside_imageUrl);   
            }
          });
        });
      };

      const handleFileUpload_outsideCentre = async (files_outside:any) => {
        console.log("outside_proof_image:::: in handle file upload outside ",files_outside);
        console.log("length of outside image proof:::  outside",files_outside);
        const dirName = 'uploadProofs';
        const urlhash = params.projectLinkKey;
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID || '',
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY || '',
          region: 'ap-south-1',
        });
        const s3 = new AWS.S3();
        let uploadCounter = 0;

        Array.from(files_outside).forEach((file:any) => {
          const fileName = file?.name;
          const filePath = `${dirName}/${urlhash}/${vle_ID}/${fileName}`;
          const uploadParams = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME || '',
            Key: filePath,
            Body: file,
          };
          s3.upload(uploadParams, (err, data) => {
            if (err) {
              console.error(`Error uploading file ${fileName}:`, err);
            } else {
              console.log(`File ${fileName} uploaded successfully. Location:`, data.Location);
              outside_imageUrl =  data.Location;
              //uploadedImageUrls.push(imageUrl);        
            }
            uploadCounter++;
            if (uploadCounter === files_outside.length) {
                console.log("fileList for inside upload image fn :::: ",fileList);
                handleFileUpload_insideCentre(fileList);
                console.log("outside_imageUrl:::",outside_imageUrl);
            }
          });
        });
      };

    const removeFile = (uid: string) => {
        setFileList((list: any[]) => (
            list.filter((item: any) => item?.uid !== uid)

        ));
    }

    useEffect(() => {
        const newSizeExceeded = fileList.some((f: any) => f.size > TEN_MB);
        console.log("newSizeExceeded::",newSizeExceeded);
       
        if (loadingFiles) setloadingFiles(false);
        console.log("isSizeExceeded.current:::",isSizeExceeded);
    }, [fileList?.length]);


    const toolTipText = () => {
        let text = ''
        if (fileList.length == 1) {
          text = 'please Complete all the steps of uploading.'
        } 
        console.log("text::", text);
          return text;
    }

    const updateTime = async () => {
        let vle_ID = sessionStorage.getItem('vleId');
    
        const queryParams = `vle_id=${JSON.parse(vle_ID)}&submit_flag=True&url_hash=${params.projectLinkKey}`;
        httpService.put(process.env.REACT_APP_DESIGN_URI, '/api/project/updateVleData/?' + queryParams, getTokenOrPublic(state), {})
          .then(response => {
              setShowSuccess(true);
              setProofSubmitted(true);
          })
          .catch(error => {
            console.error(error);
          });
    }

    const updateImageUrls = async (outside_imageUrl:any,inside_imageUrl:any) => {
        console.log("outside_imageUrl::",outside_imageUrl);
        console.log("inside_imageUrl::",inside_imageUrl);

        let vle_ID = sessionStorage.getItem('vleId');
        let folder_name = sessionStorage.getItem('folder_name');

        const queryParams = `vle_id=${JSON.parse(vle_ID)}&project_link="${encodeURIComponent(projectURLLink)}"&url_hash=${params.projectLinkKey}&outside_imageUrl="${outside_imageUrl}"&inside_imageUrl="${inside_imageUrl}"&folder_name="${folder_name}"&submit_flag=True`;
        console.log("queryParams:::",queryParams);

        httpService.put(process.env.REACT_APP_DESIGN_URI, '/api/project/updateVleData/?' + queryParams, getTokenOrPublic(state), {})
          .then(response => {
              setShowSuccess(true);
              setProofSubmitted(true);
          })
          .catch(error => {
            console.error(error);
        });
    }

    const handleNextButton = () => {
            setNextButtonClick(true);
            setImageSelected(false);
            setFileList([]);
    }


    return <Drawer
        visible={true}
        closable={false}
        destroyOnClose
        forceRender
        className="upload-proofs"
        onClose={() => {
            // if(!loading)
            handleClose()
        }}
        footer={<div style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            flexDirection: "column-reverse"
        }}>

            
            {!showSuccess && 
             <Tooltip
             overlayStyle={{
               color: "black",
               background: "white",
             }}
             placement='bottom' title={toolTipText()}
             content = {<Button
                type="primary"
                className="primary"
                disabled={fileList.length == 0 || !nextButtonClick}
                loading={loading}
                onClick={() => {
                   // updateTime(); 
                    console.log("fileList.....",fileList);
                    //handleFileUpload_insideCentre(fileList);
                    handleFileUpload_outsideCentre(ProofImage_outside);   
                  }}
            >
                Submit Proof
            </Button>}>
                </Tooltip>}
        </div>}
    >
        {!showSuccess || !nextButtonClick ? <Upload
        expirationTime={expirationTime}
            fileList={fileList}
            fileUpload={fileUpload}
            handleChange={handleChange_insideCentre}
            OutsidehandleChange={handleChange_outsideCentre}
            removeFile={removeFile}
            imageSelected={imageSelected}
            handleNextButton={handleNextButton}
            nextButtonClick = {nextButtonClick}
           isSizeExceeded = {isSizeExceeded}
           // loadingInsideImage = {loadingInsideImage}
        /> :

            <SubmissionSuccesful handleClose={handleClose} />}

            {/* {nextButtonClick ? <Upload
        expirationTime={expirationTime}
            fileList={fileList.length = 0}
            fileUpload={fileUpload}
            handleChange={handleChange}
            removeFile={removeFile}
            imageSelected={imageSelected}
            handleNextButton={handleNextButton}
            nextButtonClick = {nextButtonClick}
        /> : 
        <></>

            } */}



    </Drawer>
}
export default UploadProofs;



const Upload = ({ fileList, fileUpload, handleChange, removeFile, expirationTime, imageSelected ,handleNextButton, nextButtonClick, OutsidehandleChange, isSizeExceeded}) => (
    <>
        <div className="expiration-time">
            <p> <ClockCircleOutlined className='icon'/> Your upload window expire in {calculateTimeRemaining(expirationTime)}</p>
        </div>
        <div className='header'>
            <h2 className='title'>Submit Proof Here</h2>

            <h6 className='sub-title'>Please submit the picture of shop with the banner for verification</h6>
        </div>
        <div className='instructions'>
            <h2>Instructions</h2>
            <ul>

                <li>Upload picture in png, jpg, or JPEG format.</li>
                <li>Each picture should not be more than 10MB.</li>
                <li>Picture can be uploaded from computer.</li>
            </ul>
        </div>
       
        {fileList.length > 0 && imageSelected ? <div className="content ploaded-content">
            <span>
                <div className={fileList.length > 0 ? "ant-upload ant-upload-drag uploader-card-view" : "ant-upload ant-upload-drag"}
                    //</span>style={{ padding: "30px 30px 20px 30px" }}
                    >
                   <div className="upload-card">
                        <div><p style={{color: "#7335cb", fontSize:"15px"}}>Agent Centre picture to be Captured</p>
                            <div className="horizontal-line"></div>
                            {nextButtonClick ? <div className="horizontal-line2" style={{backgroundColor: '#7335cb'}}></div> : <div className="horizontal-line2"></div>}
                            <br/> 
                            <p style={{color : "balck", fontWeight: 'bold'}} >{nextButtonClick ? "Inside the Centre":"Outside the Centre" }<br/></p> 
                            <p style={{ color : "balck"}}>{nextButtonClick ? "1 picture from inside the centre" : "1 picture from outside the centre"}</p>
                        </div>

               
                    <span
                        style={{ display: "block", maxHeight: "100%", overflow: "scroll", padding: "10px", height: "80%", width: "100%" }}
                        className="ant-upload-btn" role="button">
                        <input

                            type="file"
                            ref={fileUpload}
                            accept= ".jpg,.jpeg,.png"
                            style={{ display: 'none' }}                       
                            onClick={(e: any) => e.target.value = null}
                            onChange={handleChange}
                         />
                        {fileList.length > 0 && <div
                            style={{ justifyContent: "center" }}
                            className="ant-upload-list ant-upload-list-picture-card">
                            {fileList.map((file: any) => {

                               const isExceeding : boolean = file.size > TEN_MB;
                                const extension: string = file.name.split('.').pop().toLowerCase();
                                const hasExtension: boolean = file.name.split('.').length > 1;
                                const url: string = file.type.includes('image/') && extension !== "psd" ? file.thumbUrl : getPreview(hasExtension ? extension : "folder");
                                

                                return  <div className="ant-upload-list-picture-card-container"
                                    key={file.uid}>
                                    <div className="card tooltip-primary bottom" data-tip={file.name}>
                                    <div className="card-body">
                                            {(isExceeding) ? <div className="exceeded">
                                                {/* <span onClick={() => removeFile(file.uid)}>

                                                    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white" />
                                                    </svg>

                                                </span> */}
                                                {/* <span className="danger-text">
                                                    Image should be less than 10MB.
                                                </span> */}
                                                </div> 
                                                : <>
                                            
                                                {url && <img src={url} onError={(e: any) => {
                                                    e.target.onerror = null;
                                                    e.target.src = RandomPreview
                                                }} 
                                                />}

                                                <div className="overlay">
                                                    <span className="close" onClick={() => removeFile(file.uid)}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            </>}
                                        </div>

                                        </div>
                                        {/* <div className="footer w-100">
                                            <p className="text">{file.name}</p>
                                        </div> */}
                                    {/* </div> */}
                               </div> 
                                
                            })}
                        </div>}
                    </span>
                    {!nextButtonClick ? 
                    <Button 
                    type="default" 
                    className="primary"
                    style={{ margin: "0px auto 10px auto" ,
                    backgroundColor: "#7335cb", color: "white", width:"20%" }}
                    variant="outlined"
                    onClick={handleNextButton}
                        >
                    Next
                    </Button> : <></>}
                    </div>     
                </div>                
            </span> 
        </div>
            : 
            <div className="content">
                <span>
                    <div className={fileList.length > 0 ? "ant-upload ant-upload-drag uploader-card-view" : "ant-upload ant-upload-drag"}
                        >
                        <span className="ant-upload ant-upload-btn" role="button">
                            <div className="ant-upload-drag-container">
                                <div className="upload-card">
                                   <div><p style={{color: "#7335cb", fontSize:"15px"}}>Agent Centre picture to be Captured</p>
                                   <p>   </p>
                                   <div className="horizontal-line"></div>
                                   {nextButtonClick ? <div className="horizontal-line2" style={{backgroundColor: '#7335cb',  borderWidth: '1px', borderStyle: 'solid', borderColor: '#7335cb'}}></div> : <div className="horizontal-line2"></div>}
                                   <br/> 
                                   <p style={{color : "balck", fontWeight: 'bold'}} >{nextButtonClick ? "Inside the centre":'Outside the Centre' }<br/></p> 
                                   <p style={{ color : "balck"}}>{nextButtonClick ? "1 picture from inside the centre":"1 picture from outside the centre"}</p><br/><br></br><br/>
                                   </div>
                                <input
                                ref={fileUpload}
                                type="file"
                                accept= ".jpg,.jpeg,.png"
                                style={{ display: 'none' }}
                                onClick={(e: any) => e.target.value = null}
                                onChange={nextButtonClick ? handleChange :OutsidehandleChange}
                                 />
                                    <Button type="default" className="select-files" onClick={(e: any) => {
                                        let node: any = fileUpload?.current;
                                        node?.click();
                                    }}> <Icon component={iconFolder} />Choose from Device</Button>
                                     {isSizeExceeded ? <div className="exceeded">  <span  className='danger-text' style={{color:"red"}}>Image should be less than 10MB.</span> 
                                </div> : 
                                <></>
                                }
                                </div>
                               
                            </div>
                        </span>
                       

                    </div>
                </span>
                {fileList.length > 0 && <div className="ant-upload-list ant-upload-list-picture-card">
                    {fileList.map((file: any) => {

                        const isExceeding: boolean = file.size > TEN_MB;
                        const extension: string = file.name.split('.').pop().toLowerCase();
                        const hasExtension: boolean = file.name.split('.').length > 1;
                        const url: string = file.type.includes('image/') && extension !== "psd" ? file.thumbUrl : getPreview(hasExtension ? extension : "folder");

                        return <div className="ant-upload-list-picture-card-container" key={file.uid}>
                            {/*<div className="card tooltip-primary bottom" data-tip={file.name}>
                                 <div className="card-body">

                                    {(isExceeding) ? <div className="exceeded">
                                        <span onClick={() => removeFile(file.uid)}>
                                            <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white" />
                                            </svg>
                                        </span>
                                        <span className="danger-text">

                                            You can't transfer more than 10MB at a time

                                        </span>
                                    </div> : <>
                                        {url && <img src={url} onError={(e: any) => {
                                            e.target.onerror = null;
                                            e.target.src = RandomPreview
                                        }} />}
                                        <div className="overlay">
                                            <span className="close" onClick={() => removeFile(file.uid)}>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white" />
                                                </svg>
                                            </span>
                                        </div>
                                    </>}

                                </div> */}
                                {/* <div className="footer w-100">
                                    <p className="text">{file.name}</p>
                                </div> 
                            </div>*/}

                        </div>
                    })}
                </div>}
            </div>}

           
    </>
)

const SubmissionSuccesful = ({handleClose}) => (
    <><div className='header'>
        <h2 className='title'>Proof Submission</h2>
        <h6 className='sub-title'>Please submit the picture of shop with the banner for verification</h6>
    </div>
        <div className='successful-submission'>
            <img src={Approved} alt="successful-submission" />

            <h3>Proof Submission<br></br>
                Complete</h3>
        </div>
        <div className='footer'>
        <Button
                type="primary"
                className="primary"
                style={{float:'right'}}
                onClick={() => {
                    handleClose()       
                  }}
            >
                Close
            </Button>
        </div>
        </>
)

function calculateTimeRemaining(expirationDate) {
    var expirationMoment = moment(expirationDate);
    var currentMoment = moment();

    currentMoment = currentMoment.utcOffset(0,true);

    var duration = moment.duration(expirationMoment.diff(currentMoment));
    var days = Math.floor(duration.asDays());
    var hours = Math.floor(duration.asHours() % 24);

    var hoursFormatted = hours.toString().padStart(2, '0');

    
    var timeRemaining = days + " days : " + hoursFormatted + " hours";
    console.log(timeRemaining)
    return timeRemaining;
  }
