//  Component used
//  1. Color more option and top filter
//  2. Template more option and top filter
//  3. Design more option and top filter
//  4. hpProject more option and top filter
//  5. library more option and top filter
//  6. Folder more option and top filter
//  7. Project and hpproject view

// There are 4 main props to this component
// 1.componentCaller--->Specify which component is calling dropdownOption component
// 2.projectType---> Specify subType of componentCaller (eg.project,hpproject,file,color,template etc)
// 3.dropdownstep---> 0->design and hp menu
//                    1->Move to folder menu
//                    2->Download menu
// 4.project ---> particular project detail

import React, { FC, useState, useEffect, useMemo } from 'react';
import Icon, { CopyOutlined, DeleteOutlined, EditOutlined, FolderOutlined, RightOutlined, SendOutlined, SnippetsOutlined, LeftOutlined, CloseCircleOutlined, DownloadOutlined, EyeOutlined, PlusCircleOutlined, DownCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuItem, Menu, Select, SelectOption, MenuItemGroup, Tooltip } from 'pyxis-ui-kit';
import { useSelector, useDispatch } from 'react-redux';
import { ExportState, Folder, FolderState, RolesState } from '../../../store/types';
import { fetchFolders, setFolders, moveFolders, moveFiles, moveProjects, moveHyperProjects, setProjects, setSelectedProject, duplicateProject, setSelectedFolders, cancelApprovalRequest, reSendForApproval, fetchLibraryFile, getUsersData, generateShareableLink } from '../../../store/actions';
import './index.scss'
import { openToast } from '../../../Toasts';
import { IMAGE_BASE_URL, S3_BASE_URL } from '../../../utilities/paths';
import { downloadFile, getIsTypeEditable, permissionDeniedClick } from '../../../utilities/common-function';
import download from '../../../assets/svg/download.svg';
import { Link, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { exportMultiple, exportProject, setExportedProjects } from '../../../store/actions/export';
import { ReactComponent as menuArrow } from '../../../assets/svg/menuArrow.svg';


interface DropDownInterface {
    // Open more parameter
    componentCaller: string,
    project: any,
    projectType: string,
    projectIndex?: number,
    setPublicLinkDrawerProps?: Function
    setDrawerVisible?: Function,

    scrollToTop?: Function,
    setIsDeleteOverlay?: Function,
    setIsOpenMoreVisible: Function,
    isOpenMoreVisible?: boolean,
    setCurrentProject?: Function,

    // Topfilter parameter
    isTopFilter?: string,
    isAllSelected?: boolean,
    isPartiallySelected?: boolean,
    dropDownStep: number,
    setDropDownStep: Function,
    isEditEnabled?: boolean,
    hasFailedProjects?: boolean,
    isMultipleDownloadDisable?: boolean,
    isBothFormatSelected?: boolean,
    renderData?: any,
}

export const DropDownOption: FC<DropDownInterface> = ({ componentCaller, project, projectType, projectIndex, scrollToTop, setIsDeleteOverlay, isTopFilter, setDrawerVisible,
    isAllSelected, isPartiallySelected, isOpenMoreVisible, setIsOpenMoreVisible, setCurrentProject, dropDownStep, setDropDownStep, renderData, setPublicLinkDrawerProps,
    isEditEnabled, hasFailedProjects, isMultipleDownloadDisable, isBothFormatSelected }) => {
    const [movefolderParentId, setMoveFolderParentId] = useState(0)
    const [moveFolder, setMoveFolder] = useState<any>({})
    const [parentFolder, setParentFolder] = useState<any>([])
    const [selectedKeys, setSelectedKeys] = useState<any>([])
    const [folderList, setFolderList] = useState<any>([])
    const [qualityValue, setQualityValue] = useState(100);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector((state: { folders: FolderState }) => (state.folders));
    const userPermission: any = useSelector((state: { roles: RolesState }) => (state.roles.userPermissions.length !== 0 ? state.roles.userPermissions : []))
    const navProductList: any = useSelector((state: { roles: RolesState }) => (state.roles.navProductList.length !== 0 ? state.roles.navProductList : []))
    const selectedProductId: any = useSelector((state: { roles: RolesState }) => (state.roles.selectedProductId))
    const currentRole = navProductList.find(({ _id }) => _id === selectedProductId)?.role || ''
    const canStartApproval = useMemo(() => userPermission.includes('start-cancel-approval-flow'), [userPermission]);
    const { folders, banners, selectedFolders, activeFolderId, bannerData, selectedProjects, isBannerDetailView } = state;
    const { exportedProjects } = useSelector((state: { export: ExportState }) => (state.export));

    // It will update move folder list on delete/add of folder
    useEffect(() => {
        setFolderList(folders)
    }, [folders])

    // When move to is open , list should first display folders of My Folder
    // If we will not filter folders, list will display folders of current folder
    const filteredFolders = () => {
        let filteredFolders: Folder[] = folders.filter(folder => folder.parent === 0);
        setFolderList && setFolderList(filteredFolders)
        setMoveFolder([])
    }

    // It will update move folder list on delete/add of folder
    useEffect(() => {
        setFolderList(folders)
    }, [folders])

    const cancelApproval = () => {
        if (projectType) {
            dispatch(cancelApprovalRequest({ entity_id: project.id, entity_type: projectType }, (res: any, err: boolean) => {
                if (!err && projectIndex !== undefined) {
                    banners[projectIndex].status = undefined;
                    dispatch(setProjects(banners))
                }
            }))
            setIsOpenMoreVisible(false)
            setDropDownStep(0)
        } else {
            openToast('error', 'Invalid Entity Id!')
        }
    }

    const resendForApproval = () => {
        dispatch(reSendForApproval({ entity_id: project.id, entity_type: projectType }, (res: any, err: boolean) => {
            if (!err && projectIndex !== undefined) {
                banners[projectIndex].status = "pending";
                dispatch(setProjects(banners))
            }
        }))
        setIsOpenMoreVisible(false)
        setDropDownStep(0)
    }


    const fetchSubFolder = (id: number, e: any, folders?: Folder) => {
        folders ?
            // moving forward in move folder list
            dispatch(fetchFolders({ type: projectType, parent: [id] }, (res: any, err: boolean) => {
                if (!err) {
                    setFolderList && setFolderList(res.results)
                    setMoveFolder(folders)
                    setParentFolder((Folders: Folder[]) => [...Folders, folders])
                }
            }))
            // moving backward in move folder list
            : dispatch(fetchFolders({ type: projectType, parent: [moveFolder.parent] }, (res: any, err: boolean) => {
                if (!err) {

                    setFolderList && setFolderList(res.results)
                    const folder = parentFolder.find((folder: Folder) => folder.id === res.results[0].parent)
                    folder !== undefined ? setMoveFolder(folder) : setMoveFolder({})
                }
            }))
        setMoveFolderParentId(id)
    }

    // After moving project to folder increasing content_count of folder
    const afterMove = (projectId: number[], currentFolder: any) => {
        let files: any = banners.filter((obj: any) => (projectId.indexOf(obj.id) === -1));
        let filesToMove: any = banners.filter((obj: any) => (projectId.indexOf(obj.id) !== -1));
        dispatch(setProjects(files));
        if (isTopFilter) { dispatch(setSelectedProject([])) }
        openToast('success', `${projectType === "file" ? `File${filesToMove.length > 1 ? 's' : ''}` : projectType === "project" ? `Project${filesToMove.length > 1 ? 's' : ''}` : `Creative automation project${filesToMove.length > 1 ? 's' : ''}`} moved successfully`);
        let index: number = folders.findIndex((f: any) => f.id === movefolderParentId);
        if (index > -1) {
            let count = folders[index].content_count
            folders[index].content_count = (count === undefined ? 0 : count) + projectId.length;
            dispatch(setFolders(folders))
        }
    }

    const moveToFolder = (currentFolder: any) => {

        let projectId: number[] = [currentFolder.id]
        console.log(selectedFolders.length, folders.length, selectedProjects.length)
        console.log(currentFolder?.type)
        const allowFolder = selectedFolders.length > 0 || currentFolder.type == "project"
        if (allowFolder && selectedFolders.length !== folders.length && selectedProjects.length === 0) {
            if(projectId[0] === undefined)
            projectId = selectedFolders
            dispatch(moveFolders({ selectedFolders: projectId, destinationFolderId: movefolderParentId }, (res: any, err: boolean) => {
                if (!err) {
                    let folder: any = folders.filter((obj: any) => (projectId.indexOf(obj.id) === -1));
                    let foldersToMove: any = folders.filter((obj: any) => (projectId.indexOf(obj.id) !== -1));
                    dispatch(setSelectedFolders([]))
                    openToast('success', `Folder${foldersToMove.length > 1 ? 's' : ''} moved successfully`);
                    let index: number = folder.findIndex((f: any) => f.id === movefolderParentId);
                    if (index > -1) {
                        let count = folder[index].subfolder_count
                        folder[index].subfolder_count = (count === undefined ? 0 : count) + projectId.length;
                    }
                    dispatch(setFolders(folder))
                }
            }))
        } else if ((selectedProjects.length > 0 && selectedFolders.length > 0)) {
            openToast('error', 'Can not move files and folders together at once')
        }
        else {
            if (projectId[0] === undefined)
                projectId = selectedProjects
            if (projectType === "file") {
                dispatch(moveFiles({ selectedFiles: projectId, destinationFolderId: movefolderParentId }, (res: any, err: boolean) => {
                    if (!err) {
                        afterMove(projectId, currentFolder)
                    }
                }))
            } else if (projectType === "project") {
                dispatch(moveProjects({ selectedProjects: projectId, destinationFolderId: movefolderParentId }, (res: any, err: boolean) => {
                    if (!err) {
                        afterMove(projectId, currentFolder)
                    }
                }))
            } else if (projectType === "hpproject") {
                dispatch(moveHyperProjects({ hpproject_ids: projectId, destination_folder_id: movefolderParentId }, (res: any, err: boolean) => {
                    if (!err) {
                        afterMove(projectId, currentFolder)
                    }
                }))
            }
        }
        setIsOpenMoreVisible(false)
    }

    // Check whether move to option should be visible or not
    const checkMoveTo = () => {
        let isMoveTo: boolean = true
        if (componentCaller === "folder") {
            // don't show folder menu if we only have one folder in My Folder 
            if (activeFolderId === 0) {
                let count: number = (folders).reduce((acc: number, val: any) => {
                    if (val.parent === 0) {
                        return acc + 1;
                    } else {
                        return acc;
                    }
                }, 0)
                isMoveTo = count > 1
            }
        } else {
            // don't show in project menu if we have zero folder 
            if (folders.length === 0) {
                isMoveTo = false
            }
        }
        return isMoveTo
    }

    // Check whether dropDownoption should be visible or not
    const checkIsDisabled = () => {
        let isDisabled: boolean = false
        if (isTopFilter) {
            // top filter(download selected,move to,delete selected) will be disabled if nothing is selected(in folder or project)
            isDisabled = !isPartiallySelected
            // Only for move selected(multiple)
            if (isTopFilter === "move") {
                if (componentCaller === "folder") {
                    if (activeFolderId === 0) {
                        // if all folder are selected in My Folder we don't have any destination folder to move
                        isAllSelected && (isDisabled = isAllSelected)
                    }
                } else {
                    // move selected will be disabled in project topfilter if we don't have any folder
                    if (folders.length === 0) {
                        isDisabled = true
                    }
                }
            } else if (isTopFilter === "download" && isPartiallySelected && projectType === "project") {
                // download selected will be disabled if selected project have failed project
                // enable multiple download
                isDisabled = (hasFailedProjects)
                // download selected in project/hp view should be enabled all time
            } else if (isTopFilter === "singleDownload") {
                if (componentCaller === "projectDetail") {
                    return false;
                }
            }
        }

        if (selectedProjects.length > 0) isDisabled = false
        return isDisabled
    }

    const handleOriginalExport = (project_id) => {
        let selectedProject = banners.find(
            (p: any) => p.id === selectedProjects[0]
        );

        const handleExport = (exportType: string, qualityVal?: number) => {
            let payload: any = {
                project_type: projectType,
                output_format: exportType,
                quality: qualityVal ? qualityVal : undefined,
                projects: [project_id],
                folders: []
            }
            console.log(payload)
            let isDownloadEnable: boolean = true

            // Multiple project or multiple hp download
            let projectDetail = selectedProjects
            // For selected download in hp view wait until hp is loaded in backend
            // In project view or hp view wait until project or hp is loaded in backend

            if (isDownloadEnable) {
                setLoader(true);
                dispatch(exportMultiple(payload, (res: any, err: boolean) => {
                    if (!err) {
                        // after dispatch of setExportedProjects 'DownloadPopup' component will appear
                        dispatch(setExportedProjects([...exportedProjects, res.data]));
                        // setIsOpenMoreVisible(false)
                        // setSelectedKeys([])
                        setDropDownStep(0)
                    } else {
                        openToast('error', 'Unable to export!')
                    }
                    setLoader(false);
                }))
            }
        }

        handleExport('png')
    };

    // handleExport called from 
    // 1. topfilter of hp
    // 2. topfilter of project
    // 3. view of project
    // 4. view of hp
    // 5. selected download in hp view
    const handleExport = (exportType: string, qualityVal?: number) => {
        let payload: any = {
            project_type: projectType,
            output_format: exportType,
            quality: qualityVal ? qualityVal : undefined
        }
        let isDownloadEnable: boolean = true
        let projectDetail = [project]

        // Multiple project or multiple hp download
        if (isTopFilter === "download" && componentCaller !== "projectDetail") {
            projectDetail = selectedProjects
        }
        // For selected download in hp view wait until hp is loaded in backend
        // In project view or hp view wait until project or hp is loaded in backend
        else if ((isTopFilter === "download" || isTopFilter === "singleDownload") && componentCaller === "projectDetail") {
            if ((projectType === "hpproject" && (renderData?.render_status === "COMPLETED" || (bannerData?.rendered_count === bannerData?.count))) || projectType === 'project') {
                projectDetail = [project]
            } else {
                isDownloadEnable = false
                openToast('info', 'Please wait for combinations to render!');
            }
        }
        // To download single hp or design from menu of projectCard(single download from openmore)
        else {
            projectDetail = [project.id]
        }
        payload[projectType === "hpproject" ? "hpprojects" : "projects"] = projectDetail
        if (isDownloadEnable) {
            setLoader(true);
            dispatch(exportProject(payload, (res: any, err: boolean) => {
                if (!err) {
                    // after dispatch of setExportedProjects 'DownloadPopup' component will appear
                    dispatch(setExportedProjects([...exportedProjects, res.data]));
                    setIsOpenMoreVisible(false)
                    setSelectedKeys([])
                    setDropDownStep(0)
                } else {
                    openToast('error', 'Unable to export!')
                }
                setLoader(false);
            }))
        }
    }

    const handleSelectChange = (value: any) => {
        setQualityValue(value);
    }

    const handleFileDownload = (project: any) => {
        if (!project.rendered_file.includes('null?q=')) {
            let rendered_file = project.rendered_file.replace(/\?.*/, '');
            downloadFile(rendered_file, 'library_file');
        } else {
            openToast('error', 'Unable to export file!')
        }
        setIsOpenMoreVisible(false)
    }

    const isRendering: boolean = (projectType === 'project' || projectType === 'hpproject') && (project.render_status === "INPROGRESS" || (project.ready_status && project.ready_status !== "COMPLETED" && project.ready_status !== "FAILED"));
    const notReadyOrNotRendered: boolean = ((project.ready_status && project.ready_status === "FAILED") || project.render_status === "FAILED");
    return <>{
        // isMultipleDownloadDisable && selectedFolders.length==0?
            <Dropdown
                key={project.id}
                trigger={['click']}
                getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                visible={isOpenMoreVisible}
                onVisibleChange={(flag: boolean) => {
                    setIsOpenMoreVisible(flag)
                    setSelectedKeys([])
                    setDropDownStep(0)
                    setMoveFolderParentId(0)
                    setMoveFolder({})
                }}
                placement="bottomRight"
                disabled={checkIsDisabled()}
                overlay={
                    <Menu
                        selectable={true}
                        // 3 css class for
                        //1.Download dropdown size
                        //2.Move dropdonw size
                        //3.DropDown size in hpproject and design
                        className={dropDownStep === 2 ? "folder-option download" : dropDownStep === 1 ? "folder-option move" : "folder-option"}
                        onClick={(e: any) => { e.domEvent.stopPropagation() }}
                        onDoubleClick={(e: any) => { e.stopPropagation() }}
                        selectedKeys={selectedKeys}
                        onSelect={({ selectedKeys }) => setSelectedKeys(selectedKeys)}
                    >
                        {/* dropdown of hp and design */}
                        {dropDownStep === 0 ?
                            <>
                                {checkMoveTo() && <MenuItem className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                    onClick={() => {
                                        if (componentCaller === "project" && !project.can_manage) {
                                            permissionDeniedClick()
                                        } else {
                                            if (componentCaller === "folder") setDrawerVisible && setDrawerVisible('folderRename')
                                            else if (componentCaller === "color") setDrawerVisible && setDrawerVisible('editColor')
                                            else setDrawerVisible && setDrawerVisible('rename')
                                            setCurrentProject && setCurrentProject(project)
                                            setIsOpenMoreVisible(false)
                                            setSelectedKeys([])
                                        }
                                    }}>
                                    <EditOutlined className="drop-down-icon" />{componentCaller === "color" ? "Edit color" : "Rename"}</MenuItem>
                            }
                            {projectType === 'project' && project.render_status === "COMPLETED" && getIsTypeEditable(project.file_type, project.type) &&
                                <>
                                    <MenuItem className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                        onClick={() => {
                                            if (componentCaller === "project" && !project.can_manage) {
                                                permissionDeniedClick()
                                            } else if (componentCaller === "project" && project.can_manage) {
                                                setIsOpenMoreVisible(false)
                                                dispatch(duplicateProject({ id: project.id }, (res: any, err: boolean) => {
                                                    if (!err) {
                                                        let duplicatedData: any = {
                                                            id: res.data.id,
                                                            parent: activeFolderId ? activeFolderId : 0,
                                                            title: res.data.title,
                                                            type: res.data.type,
                                                            rendered_file: IMAGE_BASE_URL + res.data.rendered_file + '?q=40',
                                                            created_by: res.data.created_by,
                                                            created_at: res.data.created_at,
                                                            can_manage: true,
                                                            render_status: "INPROGRESS",
                                                            total_count: res.data.total_count,
                                                            rendered_count: res.data.rendered_count,
                                                            rendering_count: res.data.rendering_count,
                                                        }
                                                        dispatch(setProjects([duplicatedData, ...banners]))
                                                        scrollToTop && scrollToTop()
                                                    } else {
                                                        openToast('error', 'Unable to Duplicate')
                                                    }
                                                }))
                                            }
                                        }}
                                    >
                                        <CopyOutlined className="drop-down-icon" />Duplicate
                                    </MenuItem>
                                    {(project.type !== "image_set" && project.type !== "variant_set") && <MenuItem className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                        onClick={() => {
                                            if (componentCaller === "project" && !project.can_manage) {
                                                permissionDeniedClick();
                                            } else {
                                                setDrawerVisible && setDrawerVisible('addToTemplate')
                                                setCurrentProject && setCurrentProject(project)
                                                setIsOpenMoreVisible(false)
                                            }
                                        }}
                                    >
                                        <PlusCircleOutlined className="drop-down-icon" />Add to Template
                                    </MenuItem>}
                                    <MenuItem
                                        className={componentCaller === "project" && (project.can_manage && isEditEnabled) ? "more-option" : "more-option permission-denied-lock"}
                                        onClick={() => {
                                            // if (componentCaller === "project" && (!project.can_manage ||!isEditEnabled)) {
                                            //     if(!project.can_manage){
                                            //         permissionDeniedClick();
                                            //     } 
                                            //     else if(!isEditEnabled) {
                                            //         openToast('warn','You don\'t have access to this feature.')
                                            //     }
                                            // } else {
                                            //     setDrawerVisible&&setDrawerVisible('creativeAutomation')
                                            //     setCurrentProject&&setCurrentProject(project)
                                            //     setIsOpenMoreVisible(false)
                                            // }
                                        }}
                                        style={{ cursor: "not-allowed", opacity: 0.5 }}
                                    >
                                        <CopyOutlined className="drop-down-icon" />Creative Automation
                                    </MenuItem>
                                </>
                            }
                            {((componentCaller === "folder" && ((projectType === "project" && project?.content_count > 0) || projectType === "hpproject")) || (!isRendering && (projectType === "hpproject" ? project.ready_status !== "FAILED" : projectType === 'project') && (!project.status || project.status === "COMPLETED") && componentCaller === 'project')) &&
                                <MenuItem className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                    onClick={() => {
                                        if (!canStartApproval) {
                                            permissionDeniedClick();
                                        } else {
                                            if (componentCaller === "project") setDrawerVisible && setDrawerVisible('approval')
                                            else if (componentCaller === "folder") setDrawerVisible && setDrawerVisible('folderApproval')
                                            setCurrentProject && setCurrentProject(project)
                                            setIsOpenMoreVisible(false)
                                        }
                                    }}
                                >
                                    <SendOutlined className="drop-down-icon" />Submit for Approval
                                </MenuItem>
                            }
                            {(projectType === "hpproject" ? project.ready_status !== "FAILED" : projectType !== 'file') && project.status === "pending" && (canStartApproval || project.can_manage) &&
                                <MenuItem onClick={cancelApproval} className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                >
                                    <CloseCircleOutlined className="drop-down-icon" />Cancel Approval
                                </MenuItem>
                            }

                            {projectType !== 'template' && !isRendering && (projectType === "hpproject" ? project.ready_status !== "FAILED" : projectType !== 'file') && project.status && project.status === "rejected" && (canStartApproval || project.can_manage) &&
                                <MenuItem onClick={resendForApproval} className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                >
                                    <CloseCircleOutlined className="drop-down-icon" />Resend for Approval
                                </MenuItem>
                            }
                            {(componentCaller === "project" ? (projectType === 'hpproject' ? project.render_status !== 'NOT_STARTED' : projectType === 'project') : componentCaller === "folder") && projectType !== 'file' &&
                                (currentRole !== 'product-coordinator' ? <MenuItem className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                    onClick={(e) => {
                                        if (componentCaller === "project" && !project.can_manage) {
                                            permissionDeniedClick();
                                        } else {
                                            if (componentCaller === "folder") {
                                                const selectedFolderInfo: any = folders.find((element) => element.id === project.id);
                                                setPublicLinkDrawerProps && setPublicLinkDrawerProps({
                                                    projectType: selectedFolderInfo.type,
                                                    projectId: project.id,
                                                    shareable_type: 'folder',
                                                    onSubmit: (data: any, callback: Function) => {
                                                        dispatch(generateShareableLink({ project_id: project.id, projectType: selectedFolderInfo.type === "hpproject" ? "hp-project" : selectedFolderInfo.type, shareable_type: 'folder', ...data }, callback));
                                                    }
                                                })
                                            } else {
                                                setPublicLinkDrawerProps && setPublicLinkDrawerProps({
                                                    projectType,
                                                    projectId: project.id,
                                                    shareable_type: 'design',
                                                    onSubmit: (data: any, callback: Function) => {
                                                        dispatch(generateShareableLink({ project_id: project.id, shareable_type: 'design', projectType: projectType === "hpproject" ? "hp-project" : projectType, ...data }, callback));
                                                    }
                                                })
                                            }
                                            setIsOpenMoreVisible(false);
                                        }
                                    }}>
                                    <span className="drop-down-icon share">
                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23 12L19 8V11H10V13H19V16L23 12ZM1 18V6C1 4.89 1.9 4 3 4H15C15.5304 4 16.0391 4.21071 16.4142 4.58579C16.7893 4.96086 17 5.46957 17 6V9H15V6H3V18H15V15H17V18C17 18.5304 16.7893 19.0391 16.4142 19.4142C16.0391 19.7893 15.5304 20 15 20H3C2.46957 20 1.96086 19.7893 1.58579 19.4142C1.21071 19.0391 1 18.5304 1 18Z" fill="#4253a9" />
                                        </svg>
                                    </span>
                                    <span>Share</span>
                                </MenuItem> : null)}
                            {componentCaller === "project" && ((projectType === 'project' || projectType === 'hpproject') && !isRendering && getIsTypeEditable(project.file_type, project.type)) &&
                                <MenuItem
                                    className={(project.psdUrl && project.type !== "image_set" && project.type !== "variant_set") ? "more-option" : "more-option permission-denied-lock"}
                                    onClick={() => { setIsOpenMoreVisible(false) }}
                                    title={!project.psdUrl ? 'Download PSD functionality is only available to projects created after 5th March' : 'Download PSD'}>
                                    <DownCircleOutlined className="drop-down-icon" />
                                    <span><a href={project.type !== "image_set" && project.type !== "variant_set" ? project.psdUrl : null} style={{ color: "#515151" }}>Download uploaded {project.file_type} {project.file_type === "psd" ? "" : project.type}</a> </span>
                                </MenuItem>}
                            <MenuItem className={componentCaller === "project" && !project.can_manage ? "more-option permission-denied-lock" : "more-option"}
                                onClick={() => {
                                    if (componentCaller === "folder") {
                                        if (userPermission.indexOf('manage-all-assets') === -1) {
                                            permissionDeniedClick();
                                        } else {
                                            setDrawerVisible && setDrawerVisible('delete')
                                            setCurrentProject && setCurrentProject(project)
                                            setIsOpenMoreVisible(false)
                                        }
                                    } else if (componentCaller === "project" || componentCaller === "color") {
                                        if (!project.can_manage) {
                                            permissionDeniedClick();
                                        } else {
                                            setIsDeleteOverlay && setIsDeleteOverlay(true)
                                            setIsOpenMoreVisible(false)
                                        }
                                    } else {
                                        setDrawerVisible && setDrawerVisible('delete')
                                        setCurrentProject && setCurrentProject(project)
                                        setIsOpenMoreVisible(false)
                                    }
                                }
                                }>
                                <DeleteOutlined className="drop-down-icon" />Delete {componentCaller === "color" && componentCaller}
                            </MenuItem>
                            {isRendering && projectType !== 'file' && <><MenuItem
                                className="more-option"
                                onClick={() => {
                                    if (projectType === "hpproject" && !isEditEnabled) {
                                        openToast('warn', 'you don\'t have access to this feature.')
                                    } else {
                                        history.push(projectType === "project" ? `/cocreate/banner-detail/?banner=${project.id}` :
                                            `/cocreate/creative-automation/detail/?banner=${project.id}`)
                                    }
                                }
                                }>
                                <EyeOutlined className="drop-down-icon" />View
                            </MenuItem>
                                {getIsTypeEditable(project.file_type, project.type) && <MenuItem>
                                    <Link to={projectType === 'project' ? ((project.type === "image_set" || project.type === "variant_set") ? `/cocreate/set/${project.id}` : `/cocreate/editor/${project.id}`)
                                        : (project.render_status === 'NOT_STARTED' ? ((project.type === "image_set" || project.type === "variant_set") ? `/cocreate/creative-automation/select/${project.id}` :
                                            `/cocreate/creative-automation/editor/${project.id}`) : `/cocreate/creative-automation/editor/${project.id}`)
                                    }
                                        onClick={(e) => { if ((!project.can_manage || !isEditEnabled)) { e.stopPropagation(); e.preventDefault(); if (!project.can_manage) { permissionDeniedClick(); } if (!isEditEnabled) { openToast('warn', 'You don\'t have access to this feature.') } } }}
                                        className={(project.can_manage && isEditEnabled) ? "more-option" : "more-option permission-denied-lock"}
                                    >
                                        <EditOutlined className="drop-down-icon" />Edit
                                    </Link>
                                </MenuItem>}
                            </>}
                        </>
                        // display folder for move to
                        : dropDownStep === 1 ?
                            <>
                                {Object.keys(moveFolder).length > 0 && moveFolder.constructor === Object ?
                                    <MenuItem className="parent-class" onClick={(e: any) => fetchSubFolder(moveFolder.parent, e)}><LeftOutlined className="back" /><span className="parent-folder">{moveFolder.name}</span></MenuItem> :
                                    activeFolderId !== 0 && <MenuItem className="parent-class" onClick={() => { setMoveFolderParentId(0) }}>
                                        <span>My Folder</span>
                                    </MenuItem>}
                                <MenuItemGroup className={(Object.keys(moveFolder).length === 1 || activeFolderId === 0) ? 'folder-group parent-folder' : 'folder-group'}>
                                    {folderList.map((folders: Folder, index: number) =>
                                    (((isTopFilter ? (folders.id === activeFolderId ? folders.subfolder_count !== selectedFolders.length : (!(project.includes(folders.id)))) :
                                        (folders.id !== project.id)) || (activeFolderId === folders.id ? (folders.subfolder_count > 1) : false)) &&
                                        <MenuItem className={!isEmpty(moveFolder) ? "noselect move-folder sub-folder" : "noselect move-folder"}
                                            key={folders.id}
                                            onClick={() => setMoveFolderParentId(folders.id)}
                                            // onDoubleClick={(e: any) => folders.subfolder_count > 0 && (folders.id !== activeFolderId ? true : folders.subfolder_count > 0) && fetchSubFolder(folders.id, e, folders)}
                                            onDoubleClick={(e: any) => folders.subfolder_count > 0 && fetchSubFolder(folders.id, e, folders)}
                                        >
                                            <div className="folder-list">
                                                <div className="move-folder-info">
                                                    <FolderOutlined className="folder-icon" />
                                                    <span className="move-folder-name">{folders.name}</span>
                                                </div>
                                                {folders.subfolder_count > 0 &&
                                                    <RightOutlined
                                                        onClick={(e: any) => fetchSubFolder(folders.id, e, folders)}
                                                        onDoubleClick={(e: any) => { e.stopPropagation() }}
                                                    />}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </MenuItemGroup>
                                {/* Disabled 
                                1. If move to option is open on My Folder's project and any folder is not selected from list
                        */}
                                <MenuItem className="move" onClick={() => { setMoveFolderParentId(0) }}>
                                    <Button type="primary" disabled={(isEmpty(moveFolder) && activeFolderId === 0 ? movefolderParentId === 0 : false) || (activeFolderId === movefolderParentId)} className="move-btn" onClick={() => moveToFolder(project)}>Move here</Button>
                                </MenuItem>
                            </> :
                            // download selected dropDown
                            <>
                                <MenuItem className="download">
                                    <div className="detail png"><img src={download} className="download-icon" /><span className="title">PNG Download</span></div>
                                    <Button type="default" onClick={() => { handleExport("png") }}>Export</Button>
                                </MenuItem>
                                <MenuItem className="download">
                                    <div className="detail"><img src={download} className="download-icon" />
                                        <div className="container">
                                            <span className="title jpeg">JPEG Download</span>
                                            <div className="jpg-container">
                                                <Select defaultValue="100" className="quality" onChange={handleSelectChange} getPopupContainer={trigger => trigger.parentNode as HTMLElement}>
                                                    <SelectOption value="100">Maximum Quality (100%)</SelectOption>
                                                    <SelectOption value="90">High Quality (90%)</SelectOption>
                                                    <SelectOption value="60">Low Quality (60%)</SelectOption>
                                                </Select>
                                                <Button type="default" onClick={() => { handleExport("jpeg", qualityValue) }}>Export</Button>
                                            </div>
                                        </div>
                                    </div>
                                </MenuItem>
                                <MenuItem className="download">
                                    <div className="detail png"><img src={download} className="download-icon" /><span className="title">HTML Download</span></div>
                                    <Button type="default" onClick={() => { handleExport("html") }}>Export</Button>
                                </MenuItem>
                            </>
                    }
                </Menu>
            }
        >
            {/* top filter */}
            {isTopFilter === "move" ?
                // onClick of button onVisibleChange of dropdown will be called
                <Button onClick={(e: any) => {
                    e.stopPropagation()
                    filteredFolders()
                }} btnsize="md" type="link" className="move-selected" >
                    <FolderOutlined className="move-icon" /><span>Move selected</span>
                </Button>
                : isTopFilter === "download" ?
                    <Tooltip
                        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                        placement="bottom"
                        title={isBothFormatSelected ? "You have selected files which has both images and other formats. Select one of them to download." : hasFailedProjects ? "Selected project have failed project." : ""}
                        content={
                            <span>
                                <Button btnsize="md" type="default"
                                    className={`download-selected `}
                                    disabled={selectedProjects.length == 0 ? true : false}
                                // disabled={(!isPartiallySelected) || (isTopFilter === "download" && projectType === "project" && (isMultipleDownloadDisable || hasFailedProjects))}
                                >
                                    {loader && <Loading3QuartersOutlined className="spin-icon" spin />}
                                    <DownloadOutlined className="download-icon" /><span>Download Selected</span>
                                </Button>
                            </span>
                        }>
                    </Tooltip>
                    // project and hp view top filter button
                    : isTopFilter === "singleDownload" ?
                        <Button btnsize="md" type={componentCaller === "projectDetail" ? "link" : "default"} className="download-selected">
                            {loader && <Loading3QuartersOutlined className="spin-icon" spin />}
                            <DownloadOutlined className="download-icon" /><span>Download project</span>
                        </Button> :
                        <Icon component={menuArrow} className={componentCaller === "folder" ? "folder-more-option project-card-option" : "project-card-option"} onClick={(e: any) => e.stopPropagation()} onDoubleClick={(e: any) => { e.stopPropagation() }} />
            }
        </Dropdown>
    }
    </>

}