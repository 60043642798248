
import React, { useEffect } from "react";
import "./index.scss";
import PromptingBackground from "../../assets/img/PromptingBackground.png";
import AiDesignerImageBoxBackground from "../../assets/img/AiDesignerImageBoxBackground.png";
import SimplePromptingImage from "../../assets/img/SimplePromptingExampleImage.png";
import { Select } from 'antd';
import { Button } from "pyxis-ui-kit";
import LoadingCard from "./components/LoadingCard";
import { ImageCard } from "./components/ImageCard";
import AiDesignerModel2 from "../../assets/img/AiDesignerModel2.png";
import { SideSelect } from "./components/SideSelect";
import { useParams } from "react-router-dom";
import { Input } from 'antd';
import starsIcon from "../../assets/svg/StarsIcon.svg";
import { HistoryRounded } from "@material-ui/icons";
import { FileOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Switch } from 'antd';
import { Radio, Space } from 'antd';
import ModePopup from "./ModePopup";
import MustContain from "./MustContain";
import FieldPopup from './MustContainPopup'
import ProductPopup from './ProductPopup'
import { openToast } from "../../Toasts";
import { incrementFileUploaded, trackAdaptGenerated, trackUploadFiles } from "../../utilities/services/mixpanel";
import axios from 'axios'
import { AuthState } from "../../store/types";
import { saveAiDesignData } from '../../store/actions/createAiquireActions';
import aiDesignerTemplate1 from '../../assets/img/aiDesignerTemplate1.png';
import aiDesignerTemplate2 from '../../assets/img/aiDesignerTemplate2.png';
import aiDesignerTemplate3 from '../../assets/img/aiDesignertemplate3.png';
import BackArrowAiDesigner from '../../assets/svg/BackArrowAiDesigner.svg';
import { createProject } from "../../store/actions";
import { useDispatch, useSelector } from 'react-redux';
const { TextArea } = Input;

const AdvancePromptingCard = () => {
    const [selectedCard, setSelectedCard] = React.useState<any>(null);
    const [advancePrompt, setAdvancePrompt] = React.useState<string>('');
    const [productPopup, setProductPopup] = React.useState<any>(false)
    const [product, setProduct] = React.useState('')
    const [selectedTemplate, setSelectedTemplate] = React.useState<any>(null);
    const [activeStep, setActiveStep] = React.useState<number>(1);
    const [selectedImage, setSelectedImage] = React.useState<any>(null);
    const [loadingScreen, setLoadingScreen] = React.useState<boolean>(false);
    const [aiResponse, setAIResponse] = React.useState<any>({});
    const { userData } = useSelector((state: { authentication: AuthState }) => (state.authentication));

    const dispatch = useDispatch();
    const props = useSelector((state: any) => ({
        templateSizes: state.create.templateSizes,
        popularSizes: state.create.popularTemplateSizes,
        productId: state.roles.selectedProductId,
        folder: state.folders.activeFolderIdCreate,
    }));
    const history = useHistory();

    const checkState = async (exportId: any) => {
        console.log(exportId)
        const response = await axios.get(`${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/status/${exportId}`, {
            headers: {
                //   header1: value1,
                'ngrok-skip-browser-warning': 'true'
            }
        });
        return response.data.request.status;
    }

    const nextHandler = async () => {

        setLoadingScreen(true);
        const { lastName, firstName, emailId } = userData;
        trackUploadFiles({
            name: `${firstName} ${lastName}`,
            email: emailId,
            workspace: "",
            numberOfFiles: 1,
            fileTypes: ["image"],
        });

        const postData: any = {
            title: 'My Project',
            type: 'image',
            product_id: props.productId,
            sizes: [{ name: "custom1", type: "custom", width: 1080, height: 1080 }],
            template: null,
            folder: null,
        }
        dispatch(createProject(postData, (res: any, isError: any) => {
            if (isError) openToast('error', 'project creation failed');
            if (!isError) {
                incrementFileUploaded();
                dispatch(saveAiDesignData({
                    aiData: aiResponse,
                    selectedImage: selectedImage,
                    selectedTemplate: selectedTemplate,
                }))
                return history.push(`/cocreate/editor/${res.data.id}?type=aidesigner`);
            }
        }))

    }

    const finished = function (id) {
        return new Promise<void>((resolve, reject) => {
            const checker = setInterval(async () => {
                console.log('Checking state', id)
                let state = await checkState(id);
                if (state !== 'COMPLETED') {
                    // no need to call checkState because you're using setInterval.
                    // state = checkState(exportId)
                    console.log('State currently is', state);
                } else {
                    clearInterval(checker);
                    resolve();
                    console.log('Clear interval');
                }
            }, 10000);
        });
    }

    const getStatus = async (uuid) => {
        await axios.get(`${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/status/${uuid}`, {
            headers: {
                'ngrok-skip-browser-warning': 'true'
            }
        })
            .then((res) => {
                console.log(res, "status response")
                if (res.data.request.status == "COMPLETED") {
                    setAIResponse(res.data)
                    setActiveStep(3)
                    setLoadingScreen(false);
                } else {
                    console.log("waiting for response...")
                }

            }).catch((err) => {
                console.log(err)

            })
    }

    const handleGenerate = async () => {
        setLoadingScreen(true);
        await axios.post(`${process.env.REACT_APP_TEXT_IMAGE_CREATER_BASE_URL}/api/generate-image/advance-prompt`,
            {
                "input_text": advancePrompt,
                "user_id": "43424-234234-234-23423-43423",
                "product_type": product,
            },).then(async (res) => {


                console.log(res, "response from midjourney")
                console.log(res.data.uuid, "uuid")
                await finished(res.data.uuid).then(() => {
                    console.log('Finished');
                    getStatus(res.data.uuid);

                    // setAIResponse(res.data)
                    console.log(res.data, "completed from midjourney")
                }).catch((err) => {
                    console.log(err)
                }
                )
            }).catch((err) => {
                console.log(err)
            })

    }

    const onChangeTextArea = (e: any) => {
        console.log(e.target.value);
        setAdvancePrompt(e.target.value);
    }

    if (loadingScreen)
        return (
            <LoadingCard />
        )

    if (activeStep == 3)
        return (
            <div className="image-card">
                <div className="back-btn">
                    <img src={BackArrowAiDesigner} />
                </div>
                <div className="header">
                    <div className="title">Choose an image</div>
                    <div className="desc">Click on the image that resonates most with your product/ad</div>
                </div>
                <div className="content">
                    {aiResponse.request.imageResponse.imageUrls.map((item, index) =>
                        <div className={selectedImage == item ? "model-image-container selected" : "model-image-container"}
                            onClick={() => setSelectedImage(item)}>
                            <img className="model-image" key={index} src={item} />
                        </div>
                    )}
                </div>
                <Button btnsize="md"
                    type="primary"
                    style={{ alignSelf: 'flex-end' }}
                    className="primary submit"
                    onClick={() => setActiveStep(4)}
                    disabled={!selectedImage}
                >
                    Next
                </Button>
            </div>
        )

    if (activeStep == 4)
        return (
            <div className="image-card">
                <div className="back-btn">
                    <img src={BackArrowAiDesigner} />
                </div>
                <div className="header">
                    <div className="title">Pick a style</div>
                    <div className="desc">Choose a layout that works best for your ad</div>
                </div>
                <div className="content">
                    {[aiDesignerTemplate1, aiDesignerTemplate2, aiDesignerTemplate3].map((item, index) =>
                        <div className={selectedTemplate == index + 1 ? "model-image-container selected" : "model-image-container"}
                            onClick={() => { setSelectedTemplate(index + 1); console.log(index) }}>
                            <img className="model-image" key={index} src={item} />
                        </div>
                    )}
                </div>
                <Button btnsize="md"
                    type="primary"
                    style={{ alignSelf: 'flex-end' }}
                    className="primary submit"
                    onClick={nextHandler}
                    disabled={!selectedTemplate}
                >
                    Next
                </Button>
            </div>
        )


    return (
        <div className="advanceprompting-card">
            <div className="input-box">
                <div className="content">
                    <div className="header">
                        <div className="title">Advance Prompting</div>
                        <div className="desc">Describe your design as you would to your designer</div>
                    </div>
                    <div className="mode-input-container" style={{ display: 'block' }} >
                        <div className="mode-input" onClick={(e) => { e.stopPropagation(); setProductPopup(true) }}>
                            <span>{product.length ? options.find((item, index) => item.key === product)?.label : 'Choose your product'}</span>
                            <span><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                        </div>
                        {productPopup &&
                            <ProductPopup
                                product={product}
                                setProduct={setProduct}
                                show={productPopup}
                                onClickOutside={() => { setProductPopup(false) }} />
                        }
                    </div>
                    <div className="custom-textarea-container">
                        <div className="custom-textarea">
                            <TextArea
                                maxLength={100}
                                style={{ height: 220, resize: 'none' }}
                                onChange={onChangeTextArea}
                            />
                        </div>
                        {advancePrompt.length === 0 && <div className="empty-textarea-overlay">
                            <div className="empty-textarea-overlay-text">
                                <div className="main-text">
                                    Start typing your prompt here.
                                </div>
                                <div className="sub-text">
                                    Or you can switch to <a onClick={() => history.push('/cocreate/AIDesigner/simple_prompting')}>Simple Prompting</a>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <Button
                        btnsize="md"
                        type="primary"
                        className="primary submit"
                        onClick={handleGenerate}
                        disabled={!(product.length && advancePrompt.length)}
                    >
                        Generate
                    </Button>
                </div>
                <div className="start-fresh">
                    <div className="title">Wanna start fresh?</div>
                    <div className="btn-container">
                        <Button>
                            <div style={{ display: "flex", alignContent: "center" }}>
                                <UploadOutlined style={{ fontSize: "16px", marginRight: "5px" }} />
                                <span> Upload Design Files</span></div>
                        </Button>
                        <Button>
                            <div style={{ display: "flex", alignContent: "center" }}>
                                <FileOutlined style={{ fontSize: "16px", marginRight: "5px" }} />
                                <span>Start from blank canvas</span></div>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="image-box">
                <img src={SimplePromptingImage} />
                <div className="prompt-card-container">
                    <PromptCard />
                </div>
            </div>
        </div>
    )
}

const CustomSelect = ({ ...props }) => {
    return (
        <div className="custom-select"><Select {...props} /></div>
    )
}

const PromptCard = () => {
    return (
        <div className="prompt-card">
            <img src={starsIcon} />
            <div className="prompt-card-content">
                Student in his 20's standing infront of his college. behind him we can see his campus and he is standing infront of it like a confident student.
            </div>
        </div>
    )
}

const options = [{ label: 'Education Loan', key: 'EDUCATION_LOAN' }, { label: 'Home Loan', key: 'HOME_LOAN' }]

export default AdvancePromptingCard;
