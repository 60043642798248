import { Folder, Typography } from "../store/types";
import { FabricGroupExtended } from '../components/shared/types';
import { openToast } from "../Toasts";
import { isEqual } from "lodash";
import ImagePreview from "../assets/img/image_preview.png";
import RandomPreview from "../assets/img/random_preview.png";
import VideoPreview from "../assets/img/video_preview.png";
import AudioPreview from "../assets/img/audio_preview.png";
import BrowserPreview from "../assets/img/browser_preview.png";
import PresentationPreview from "../assets/img/presentation_preview.png";
import DocPreview from "../assets/img/doc_preview.png";
import ZipPreview from "../assets/img/zip_preview.png";
import AiPreview from "../assets/img/ai_preview.png";
import PdfPreview from "../assets/img/pdf_preview.png";
import PsdPreview from "../assets/img/psd_preview.png";
import FolderPreview from "../assets/img/folder_preview.png";
import { VIDEO_SUPPORTED,IMAGE_SUPPORTED,AUDIO_SUPPORTED,OTHER_SUPPORTED,PRESENTATION_SUPPORTED,WORD_SUPPORTED,ZIP_SUPPORTED } from "./contants";
import { IMAGE_BASE_URL } from "./paths";
import LetterCaseAiquire from "../components/RightMenu/ItemTray/Elements/TextTray/LetterCase/LetterCaseAiquire";
import async from 'async';
import streamSaver from "streamsaver";
import { isValidPhoneNumber } from 'react-phone-number-input'
import { downloadLink } from '../utilities/services/mixpanel'

type RequestToMake = () => Promise<void>

const imageExtensions = ['jpeg', 'jpg', 'png', 'psd'];

export function formatObjToURL(obj: any) {
  let qs = '';
  for (let key in obj) {
    if (qs !== '') {
      qs += '&';
    }
    qs += key + '=' + obj[key];
  }
  return qs;
}

/**
 * Download file using url
 * @param {*} fileURL
 * @param {*} fileName
 */
export function downloadFile(fileURL: string, fileName: string) {
  try {
    window.open(fileURL, '_blank')
    let vleId = sessionStorage.getItem("vleId");
    if(fileURL!= null && vleId != null && vleId!= ""){
        downloadLink( vleId, fileURL, fileName);
    }
  } catch (err) {
    console.error(err);
    fetch(fileURL)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log('Something went wrong please try again!');
        window.open(fileURL)
      });
  }
}

/**
 * Transform cloudinary img to thumb
 * @param {*} url
 */
export const convertImgToThumbmnail = (url: string, width: number = 100) => {
  return url.replace('upload/', `upload/w_${width},q_60/`);
};

/**
 * Transform cloudinary svg to png
 * @param {*} url
 */
export const convertSvgToPng = (url: string) => {
  return url.replace('.svg', '.png');
};

/**
 * Transform cloudinary img to best quality
 * @param {*} url
 */
export const convertImgToBestQuality = (url: string) => {
  return url.replace('upload/', 'upload/q_auto:best/');
};

export const getUnique = (arr: Folder[]) => {
  var resArr: Folder[] = [];
  arr.reverse().forEach(function(item){
    var i = resArr.findIndex((x: Folder) => x.id == item.id);
    if(i <= -1){
      resArr.unshift(item);
    }
  });
  return resArr;
}

export const getVarientJson = (data:Typography[]): any => {
  let arr:any = [];
  data.map(obj=>{
    let found = arr.findIndex((o:any)=>o.name === obj.font_family);
    if(found > -1){
      arr[found] = ({
        ...arr[found],
        varients: [...arr[found].varients,obj.font_style]
      })
    }else{
      arr.push({
        name: obj.font_family,
        file: obj.file,
        is_web_font: obj.is_web_font,
        varients: [obj.font_style]
      })
    }
  })
  return arr;
}
export const isSvg = (obj: FabricGroupExtended) => {
  if (!obj) return false;
  return !!obj.isSvg;
};

export const isValidEmail = (text: string) => {
  return !!(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(text))
}

export const hideEmail = (email: string): string => {
  const firstChar = email[0];
  const firstDomainChar = email.split('@').pop()[0];
  return `${firstChar}******@${firstDomainChar}*****`
}

export const isValidPhone = (phoneNumber: string) => {
  const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return !!(reg.test(phoneNumber))
}

export const isValidPhoneNo = (phoneNumber: string) => {
  const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return reg.test(phoneNumber) && isValidPhoneNumber(phoneNumber);
}

export const isValidName = (name: string) => {
  return !!(/^[a-zA-Z ]{1,30}$/.test(name))
}

export const detectQueryString = (link: string) => {
  return !!(/\?.+=.*/g.test(link))
}

export const permissionDeniedClick = () => {
  openToast('warn','You do not have access to this feature as per your role');
}

export const getUrlProperties = () => {
  let urlArr = window.location.href.split('?');
  let path = window.location.hash.split('?')[0];
  path = path.substring(1);
  return {
    baseUrl: urlArr[0],
    search: urlArr[1]||"",
    path
  }
}

export const getFileType=(type:any)=> {

  if(type.match('image.*'))
    if(type.includes('gif'))
      return 'gif';
    else
      return 'image';

  if(type.match('video.*'))
    return 'video';

  if(type.match('audio.*'))
    return 'audio';

  if(type.match('text.*'))
    return 'text';

  return 'application';
}

export const isItImage = (type:string, file_type:string) => {
  return type && 
  (
    (file_type=== null ? type === 'image' : [...imageExtensions, 'svg'].includes(file_type?.toLocaleLowerCase()))
    || type === 'image_set'|| type === 'variant_set'
  )
}

export const isItImageFileType = (type:string, file_type:string) => {
  return type && (file_type === null ? type === 'image' : imageExtensions.includes(file_type?.toLocaleLowerCase()));
}

export const getStatusColor = (status:string) => {
  switch(status){
      case 'pending': return "#ffcc00";
      case 'approved': return "#39C521";
      case 'rejected': return "#FA4300";
  }
}

export const imageLoaderParameter=(src:string,qty_parameter:number)=>{
  return (src.includes("imgix")&&!src.includes("auto=format"))?src+(detectQueryString(src)? `&q=${qty_parameter}`: `?q=${qty_parameter}`)+'&auto=format':(src.includes("roman3")&&!src.includes("q_auto"))?src.replace('upload/', `upload/q_auto/`):src
}

export const compareStrings = (a:string, b:string) => {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();

  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

export const findRatio=(height:number,width:number,size:number)=>{
      let newWidth,newHeight
      let widthRatio=width/height
      let heightRatio=height/width
      // Vertical Image
      if(heightRatio>1){
          newWidth=size
          newHeight=(size*heightRatio)
      // Horizontal Image
      }else if(widthRatio>1){
          newWidth=(size*widthRatio)
          newHeight=size
      // Square image
      } else if(heightRatio===1&&widthRatio===1){
          newWidth=size
          newHeight=size
      }
    return [newHeight,newWidth]
}

export const getFormData = (object:any) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}

export const getObjectDiff = (obj1:any, obj2:any) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
          result.push(key);
      } else if (isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
      }
      return result;
  }, Object.keys(obj2));

  return diff;
}

export const drawerRenameTitle=(drawerVisible:string,type:string)=>{
  return (drawerVisible==='rename'?(type==="hpproject"?"Creative automation":type):drawerVisible==='addToTemplate'?'Template':drawerVisible==='creativeAutomation'&&'Creative automation')
}

export const extractMentions = (str:string):string[] => {
  var pattern = /\@~~(.*?)\~~@/g; //strting with @~~ and ending with ~~@
  return str.match(pattern)||[];
}

export const csvJSON = (csv:any):any => {
  csv = csv.replaceAll('"','')
  var lines=csv.split("\n");
  var result = [];
  var headers=lines[0].split(",");

  for(var i=1;i<lines.length;i++){

	  var obj:any = {};
	  var currentline=lines[i].split(",");

	  for(var j=0;j<headers.length;j++){
		  obj[headers[j]] = currentline[j];
	  }

	  result.push(obj);

  }
  
  return result; //JavaScript object
  // return JSON.stringify(result); //JSON
}

export const getPreview = (type:string) => {
  switch(type){
    case 'folder': return FolderPreview;
    case 'pdf': return PdfPreview;
    case 'psd': return PsdPreview;
    default: {
      if(VIDEO_SUPPORTED.includes(type)){
        return VideoPreview;
      }else if(IMAGE_SUPPORTED.includes(type)){
        return ImagePreview;
      }else if(AUDIO_SUPPORTED.includes(type)){
        return AudioPreview;
      }else if(OTHER_SUPPORTED.includes(type)){
        return BrowserPreview;
      }else if(PRESENTATION_SUPPORTED.includes(type)){
        return PresentationPreview;
      }else if(ZIP_SUPPORTED.includes(type)){
        return ZipPreview;
      }else if(WORD_SUPPORTED.includes(type)){
        return DocPreview;
      }
      return RandomPreview;
    }
  }
}

export const getIsTypeEditable = (fileType:string,projectType:string) => {
  switch(fileType){
    case 'ai':
    case 'bmp':
    case 'gif':
    case 'ico':
    case 'ps':
    case 'jpe':
    case 'jfif':
    case 'webp':
    case 'tiff': return false;
    default: {
      if(IMAGE_SUPPORTED.includes(fileType) || projectType === "image" || projectType === "image_set" || projectType === "variant_set") return true;
      return false;
    }
  }
}

export const getFolderStructure = (files: any[]) => {
  let structure:any = {};
  files.forEach((file:any)=>{
    let paths:string[] = file.webkitRelativePath.split('/');
    paths.pop(); 
    let folders:string[] = paths;
    folders.forEach((folderName:string)=>{
      while(structure[folderName]){
        structure = structure[folderName];
      }
      structure[folderName] = null;
    })
  })
  return structure;
}

export function isEmpty(obj:any) {
  for(var prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  
  return JSON.stringify(obj) === JSON.stringify({});
}

export function viewportToPixels(value:string) {
  var parts:any = value.match(/([0-9\.]+)(vh|vw)/)
  var q:any = Number(parts[1])
  var side:any = (window as any)[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
  return side * (q/100);
}

export function hexToRgb(hex:string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : {r:0,g:0,b:0};
}