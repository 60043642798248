import { GET_PSD_JSON_SUCCESS, UPDATE_PROJECT_DATA, GET_FONTS_LIST_SUCCESS_WEB, GET_FONTS_LIST_SUCCESS_UPLOAD, SET_ERR_MSG_PROJECT, CLEAR_DATA_EDITOR, UPDATE_STATUS_DATA, SAVE_AI_DESIGN_DATA } from './../types/createAiquireTypes';

import { GET_SIZES_SUCCESS, LOADING_OFF, LOADING, GET_POPULAR_SIZES_SUCCESS, GET_TEMPLATES_SUCCESS, GET_CATEGORIES_SUCCESS, CREATE_PROJECT_SUCCESS, UPDATE_PROJECT_SUCCESS, GET_PROJECT_SUCCESS, GET_TEMPLATE_DATA_SUCCESS, GET_CATEGORIES_ELEMENTS_SUCCESS, GET_ELEMENTS_CATEGORY_SUCCESS, GET_UPLOADED_FILES_SUCCESS, UPDATE_SIZE_CANVAS, GET_SUMMARY_SUCCESS } from '../types';

const initialState = {
  loading: false,
  templateSizes: [],
  popularTemplateSizes: [],
  templates: [],
  categories: [],
  currentPageTemplates: 1,
  totalPagesTemplates: 1,
  projectData: {},
  projectDataResize: {},
  templateId: null,
  categoriesOfElements: null,
  elementsOfCategory: null,
  uploadedFiles: null,
  selectedTemplateData: null,
  webFonts: [],
  uploadedFonts: [],
  errorMessage: '',
  statusData: null,
  aiDesignData: {},
}

const updateState = (oldState: any, updatedValues: any) => ({
  ...oldState,
  ...updatedValues,
  loading: false,
});

export const createAiquireReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ERR_MSG_PROJECT:
      return updateState(state, { errorMessage: action.payload });
    case GET_SUMMARY_SUCCESS:
      return updateState(state, { statusData: action.payload });
    case UPDATE_STATUS_DATA:
      return updateState(state, { statusData: action.payload });
    case CLEAR_DATA_EDITOR:
      return updateState(state, { projectData: {}, projectDataResize: {}, templateId: null, selectedTemplateData: null, webFonts: [] });
    case GET_SIZES_SUCCESS:
      return updateState(state, { templateSizes: action.payload });
    case GET_POPULAR_SIZES_SUCCESS:
      return updateState(state, { popularTemplateSizes: action.payload });
    case GET_TEMPLATES_SUCCESS:
      return updateState(state, { templates: action.payload, currentPageTemplates: action.payload.current_page, totalPagesTemplates: action.payload.pages });
    case GET_CATEGORIES_ELEMENTS_SUCCESS:
      return updateState(state, { categoriesOfElements: action.payload });
    case UPDATE_SIZE_CANVAS:
      return updateState(state, { projectDataResize: action.payload.projectData, templateId: action.payload.templateId });
    case GET_ELEMENTS_CATEGORY_SUCCESS:
      return updateState(state, { elementsOfCategory: action.payload });
    case GET_UPLOADED_FILES_SUCCESS:
      return updateState(state, { uploadedFiles: action.payload });
    case GET_FONTS_LIST_SUCCESS_WEB:
      return { ...state, webFonts: action.payload.results };
    case GET_FONTS_LIST_SUCCESS_UPLOAD:
      return { ...state, uploadedFonts: action.payload.results };
    case GET_CATEGORIES_SUCCESS:
      return updateState(state, { categories: action.payload });
    case CREATE_PROJECT_SUCCESS:
      return updateState(state, { projectData: action.payload, templateId: action.payload.data.template, selectedTemplateData: null });
    case UPDATE_PROJECT_SUCCESS:
      return updateState(state, { projectData: action.payload, projectDataResize: {} });
    case UPDATE_PROJECT_DATA:
      return updateState(state, { projectData: action.payload, projectDataResize: {} });
    case GET_PSD_JSON_SUCCESS:
      return updateState(state, { projectData: action.payload, projectDataResize: {} });
    case GET_PROJECT_SUCCESS:
      return updateState(state, { projectData: action.payload, templateId: action.payload.data.template });
    case GET_TEMPLATE_DATA_SUCCESS:
      const updatedOriginal = action.payload.data.template_json;
      const newData: any = { ...state.projectData };
      newData.data.project_json.original = updatedOriginal;
      return updateState(state, { projectData: newData, templateId: action.payload.data.id, selectedTemplateData: action.payload });
    case LOADING_OFF:
      return updateState(state, { loading: false });
    case LOADING:
      return { ...state, loading: true };
    case SAVE_AI_DESIGN_DATA:
      console.log('action.payload', action.payload)
      return updateState(state, { aiDesignData: action.payload, projectDataResize: {} });
    default:
      return { ...state };
  }
}