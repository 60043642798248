import { cloneDeep, omit, uniqueId } from 'lodash';
import { Drawer,Button, UploadDragger } from 'pyxis-ui-kit';
import React, { FC, ReactElement, useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFolder, createFolderStructure, fetchFolders, fileToProject, setCurrentFolderFile, setProjects, setUploadedBanners, setUploadedFolder, setUploadFileList } from '../../../store/actions';
import { Folder, FolderState } from '../../../store/types';
import { openToast } from '../../../Toasts';
import { detectQueryString, getPreview, getFolderStructure } from '../../../utilities/common-function';
import { IMAGE_SUPPORTED,VIDEO_SUPPORTED,AUDIO_SUPPORTED,PRESENTATION_SUPPORTED,ZIP_SUPPORTED,OTHER_SUPPORTED,WORD_SUPPORTED } from '../../../utilities/contants';
import { IMAGE_BASE_URL } from '../../../utilities/paths';
import Img from '../../ImageLoader/ImageLoader';
import './index.scss';
import { getTreeStructure,getStructure,getFilesWebkitDataTransferItems,getThumbNail } from './utils';
import RandomPreview from "../../../assets/img/random_preview.png";


const FIVE_GB = 5 * Math.pow(10, 9);

const UploadDesignsDrawer:FC<{handleClose: Function; onSubmit: Function}> = ({handleClose,onSubmit}) => {
    const {activeFolderId} = useSelector((state: { folders: FolderState})=>(state.folders));
    const [fileList,setFileList] = useState<any[]>([]);
    const [loading,setLoader]= useState(false);
    const isSizeExceeded = useRef(false);
    const dispatch = useDispatch();
    const fileUpload = useRef<any>(null);
    const folderUpload = useRef<any>(null);
    const dragCounter = useRef(0);
    const timer = useRef<any>(null);
    const [loadingFiles,setloadingFiles] = useState(false);
    const [dragging,setDragging] = useState(false);
    const state = useSelector((state: {folders: FolderState})=>(state.folders));
    const { folders } = state;
    const getActiveFolder = useCallback((id: number, type: string) => (
        folders.find(folder => folder.id === id && folder.type === type) || null
    ), [folders])

    const handleChange = (e:any) => {
        if(!loadingFiles) setloadingFiles(true);
        let files:any[] = e?.target?.files;
        for(let i = 0; i < files.length; i++) { 
            files[i].uid = uniqueId("file_");
            files[i].thumbUrl = URL.createObjectURL(files[i]);
            files[i].parent = activeFolderId;
        }
        setFileList((f:any)=>([...f, ...files]));
    };

    const handleFolderUpload = (e:any) => {
        setloadingFiles(true);
        let files:any[] = Array.isArray(e)? e: Array.from(e?.target?.files);
        // Removing hidden files
        files=files.filter((file:any)=>!file.name.startsWith('.'))
        if((Array.isArray(e) && e) || e?.target?.files?.length) {
            Promise.all(files.map(getThumbNail)).then((updatedFiles:any)=>{
                let tree:any = getTreeStructure(files);
                let {structure,hashedStructure}:any = getStructure(tree,{},{},'');
                let rootFolder:string = Object.keys(tree)[0]; 
                let size:number = files.reduce((acc:any,f:any)=>(f.size+acc),0)
                setFileList((f:any)=>([...f,{
                    name: rootFolder,
                    size,
                    type: 'folder',
                    file: tree,
                    files,
                    structure,
                    hashedStructure,
                    uid: uniqueId("folder_")
                }]))
                setloadingFiles(false);
            })
        }
    }

    const handleSubmit = () => { 
        let folders:any = fileList.filter((f:any)=>f.type === 'folder');
        Promise.all(folders.map((folder:any,index:number)=>{
            return new Promise((res:any,rej:any)=>{
                dispatch(createFolderStructure({structure: folder.structure,projectType:'project',parent_id:activeFolderId||undefined},(response:any,err:boolean)=>{
                    if(!err){
                        response.data[Object.keys(response.data)[0]].subfolder_count = Object.keys(folder.structure[folder.name]).length;
                        // let idToFolderName:any = {};
                        // let idToFolder:any = {};
                        // response.data.map((val:any)=>{
                        //     idToFolderName = {...idToFolderName,[val.id]: val.name};
                        //     idToFolder = {...idToFolder,[val.id]: val};
                        // })
                        res({data: response.data,files: folder.files,type: "folder",name: "",hashedStructure: folder.hashedStructure,root: response.data[folder.name]}); 
                    }else{
                        rej(response)
                    }
                }))
            })
        })).then((res:any)=>{
            let rootFolders:any[] = res.reduce((acc:any,folder:any)=>(
                [...acc,{...folder.root, parent: activeFolderId}]
            ),[])
            // Sorting based on folder id
            rootFolders=rootFolders.sort(function(a, b){return a.id - b.id});
            dispatch(addFolder(rootFolders));
            let currentFolderFiles:number=0
            let uploadedFolders:any[]=[]
            let list:any = fileList.reduce((acc:any,file:any)=>{
                let val:any = [];
                if(file.type === 'folder'){
                    let folderData:any = res.shift();
                    let folders:any = folderData?.hashedStructure;
                    let hashedStructureFolderIds:any[] = Object.keys(folderData.data).reduce((acc: number[], key: any) => [...acc, folderData.data[key].id], []);
                    let folderMap:any = folderData?.data;
                    let folderFiles:any = [];
                    let folderKeys:any = Object.keys(folders||{});
                    folderKeys.map((key:any,index:number)=>{
                        folders[key].map((f:any)=>{
                            f.file['parent'] = folderMap?{...folderMap[key],parent: {...folderData.root,parent: activeFolderId} }:null;
                            folderFiles.push(f.file);
                        })
                    })
                    folderData.root.totalCount=folderData.files.length
                    folderData.root.hashedStructureFolderIds=hashedStructureFolderIds
                    uploadedFolders=uploadedFolders.concat(folderData.root)
                    val = folderFiles||file.files;
                    
                }else{
                    currentFolderFiles=currentFolderFiles+1
                    val = [file];
                }
                return [...acc, ...val]
            },[])
            dispatch(setUploadedBanners([]))
            if(currentFolderFiles>0){
                let folder={
                    id:activeFolderId===0?null:activeFolderId,
                    totalCount:currentFolderFiles,
                    hashedStructureFolderIds:[activeFolderId===0?null:activeFolderId]
                }
                uploadedFolders=uploadedFolders.concat(folder)
            }
            // Sorting based on folder id
            uploadedFolders=uploadedFolders.sort(function(a, b){return a.id - b.id});
            dispatch(setUploadedFolder(uploadedFolders))
            dispatch(setCurrentFolderFile(currentFolderFiles))
            onSubmit(changeThumbNail(list), rootFolders, ()=>{
                setLoader(false);
                handleClose()
            }) ;
        }).catch((res:any)=>{
            console.log(res,"res")
            setLoader(false);
            openToast('error',res?.data?.message || "Unable to create folder")
        })
    };

    const changeThumbNail = (list:any) => {
        list.forEach((file:any)=>{
            if(file.type && file.type === "folder") changeThumbNail(file.files);
            let extension:string = file.name.split('.').pop().toLowerCase();
            let link:string = file.type.includes('image') && extension !== "psd" ?(file.thumbUrl):getPreview(file.type === "folder"?"folder":extension);
            if(file.thumbUrl !== link){
                file.thumbUrl = link;
            }
        })
        dispatch(setUploadFileList(list))
        return list;
    }

    const removeFile = (uid:string) =>{
        setFileList((list:any[])=>(
            list.filter((item:any)=>item?.uid !== uid)
        ))
    }

    useEffect(()=>{
        isSizeExceeded.current = fileList.some((f:any)=>f.size > FIVE_GB);
        // isNotSupported.current = fileList.some((f:any)=>f.type === "folder"?false:!`${IMAGE_SUPPORTED}${VIDEO_SUPPORTED}${AUDIO_SUPPORTED}${PRESENTATION_SUPPORTED}${ZIP_SUPPORTED}${OTHER_SUPPORTED}${WORD_SUPPORTED}`.includes(f.name.split('.').pop().toLowerCase()));
        // setFilesNotSupported(fileList.filter((f:any)=>f.type === "folder"?false: !`${IMAGE_SUPPORTED}${VIDEO_SUPPORTED}${AUDIO_SUPPORTED}${PRESENTATION_SUPPORTED}${ZIP_SUPPORTED}${OTHER_SUPPORTED}${WORD_SUPPORTED}`.includes(f.name.split('.').pop().toLowerCase())))
        if(loadingFiles) setloadingFiles(false);
    },[fileList?.length])

    useEffect(()=>{
        let node:any = folderUpload.current;
        if(node){
            node.directory = true;
            node.webkitdirectory = true;
        }
    },[])

    const handleDrag = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    }

    const handleDragOut = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current--
        if (dragCounter.current === 0) {
            setDragging(false);
        }
    }

    const handleDrop = (e:any) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        let flag:boolean = false;
        let node:any = folderUpload?.current;
        for (let item of e.dataTransfer.items){
            let data:any = item.webkitGetAsEntry();
            if(data.isFile){ flag = true; }
            else {
                getFilesWebkitDataTransferItems(data).then((res:any)=>{
                    res.length && handleFolderUpload(res);
                })
            }
        }
        if(flag){
            openToast('warn','Please input only folders containing atleast one file')
        }else{
            e.dataTransfer.clearData()
            dragCounter.current = 0    
        }
    }
    

    return <Drawer
        visible={true} 
        closable={false}
        destroyOnClose
        forceRender
        className="upload-designs-popup"
        onClose={()=>{
            // if(!loading)
            handleClose()
        }} 
        footer={<>
            {/* {filesNotSupported.map((file:any)=>(
                <div>{file.name}</div>
            ))} */}
            <Button type="link" className="secondary" onClick={()=>handleClose()} icon={<span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 3L6 8L10 13" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>}
                disabled={loading}
            >
                Cancel
            </Button>
            <Button type="primary" className="primary" 
                icon={<span>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.4987 2.5026L8.4987 11.8359" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.5 11.8354C14.5 13.3081 13.306 14.5021 11.8333 14.5021H5.16667C3.694 14.5021 2.5 13.3081 2.5 11.8354" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.16665 5.83594L8.50065 2.50194L11.834 5.83594" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>}
                disabled={!fileList || fileList.length === 0}
                loading={loading}
                onClick={()=>{
                    // if(isSizeExceeded.current){
                    //     openToast('info',"You can't transfer more than 5GB at a time");
                    // }else if(isNotSupported.current){
                    //     openToast('info',"Remove the not supported file formats to proceed further");
                    // }else{
                        setLoader(true);
                        handleSubmit();
                    // }
                }}
            >
                Upload
            </Button>
        </>} 
    >
        <div className="title">
            Upload files
        </div>
        {/* <div className="subtitle">
            Drag and drop files here. Or simply choose a file to upload.
        </div> */}
        <div className="subtitle">
            Choose a file to upload.
        </div>
        <div className="content">
            <span>
                <div className={fileList.length > 0 ? "ant-upload ant-upload-drag uploader-card-view" : "ant-upload ant-upload-drag"}
                onClick={(e:any)=>{
                    // e.stopPropagation();
                    // e.preventDefault();
                    let node:any = folderUpload?.current;
                    node?.click()
                }}
                    // onDragEnter={handleDragIn}
                    // onDragLeave={handleDragOut}
                    // onDragOver={handleDrag}
                    // onDrop={handleDrop}
                >
                    <span className="ant-upload ant-upload-btn" role="button">
                        <input 
                            ref={folderUpload} 
                            type="file" 
                            style={{display: 'none'}} 
                            onClick={(e:any)=>e.target.value = null} 
                            onChange={handleFolderUpload} />
                        <div className="ant-upload-drag-container">
                            <div className="upload-card">
                                <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M51.7468 16.1364C51.9213 15.3346 52.0097 14.5209 52.0109 13.7053C52.0002 6.13048 44.8276 -0.00268329 35.9904 0.00635764C29.263 0.013289 23.2592 3.62614 20.9664 9.04718C16.8611 7.64644 12.2082 9.36342 10.5739 12.8822C10.4325 13.1868 10.3167 13.4996 10.2275 13.8184C3.6724 14.6587 -0.846722 19.8947 0.133633 25.5133C1.01285 30.5524 6.06334 34.2805 12.0076 34.2784H22.0084C23.1131 34.2784 24.0086 33.5108 24.0086 32.5639C24.0086 31.6171 23.1131 30.8495 22.0084 30.8495H12.0076C7.5889 30.8495 4.00688 27.7792 4.00688 23.9917C4.00688 20.2043 7.5889 17.134 12.0076 17.134C13.1123 17.134 14.0078 16.3664 14.0078 15.4195C14.0114 13.5259 15.8054 11.9932 18.0146 11.9963C19.0595 11.9978 20.0622 12.3496 20.8083 12.9765C21.595 13.6411 22.8615 13.6331 23.6368 12.9588C23.928 12.7055 24.12 12.3811 24.1865 12.0301C25.2722 6.43144 31.4473 2.64731 37.9791 3.57793C44.5108 4.50854 48.9257 9.8015 47.8399 15.4001C47.7752 15.734 47.6912 16.0649 47.5884 16.3915C47.3585 17.1072 47.6919 17.8683 48.4146 18.2774C52.0964 20.3713 53.1007 24.6273 50.6576 27.7832C49.1789 29.6933 46.6846 30.8439 44.0102 30.8493H38.0097C36.905 30.8493 36.0095 31.6169 36.0095 32.5637C36.0095 33.5106 36.905 34.2782 38.0097 34.2782H44.0102C50.6381 34.2729 56.0061 29.663 55.9998 23.9819C55.9965 20.9582 54.4411 18.0886 51.7468 16.1364Z" fill="#CFD5F6"/>
                                    <path d="M41.4246 24.4941L31.4238 15.9219C31.2376 15.7619 31.0159 15.6354 30.7717 15.55C30.2824 15.3766 29.7329 15.3766 29.2436 15.55C28.9995 15.6355 28.7779 15.762 28.5915 15.9219L18.5907 24.4941C17.8233 25.1751 17.8454 26.2604 18.64 26.9182C19.4151 27.5598 20.6439 27.5598 21.4189 26.9182L28.0095 21.2726V46.2792C28.0095 47.2261 28.905 47.9936 30.0097 47.9936C31.1144 47.9936 32.0099 47.2261 32.0099 46.2792V21.2727L38.5965 26.9183C39.3911 27.5761 40.6573 27.5572 41.4247 26.8761C42.1733 26.2117 42.1733 25.1584 41.4246 24.4941Z" fill="#CFD5F6"/>
                                </svg>
                                {/* <div className="text">
                                    Drag folder into this area
                                </div>
                                <div className="text">
                                    or
                                </div> */}
                                <Button type="link" icon={<span>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.4987 2.5026L8.4987 11.8359" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.5 11.8354C14.5 13.3081 13.306 14.5021 11.8333 14.5021H5.16667C3.694 14.5021 2.5 13.3081 2.5 11.8354" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M5.16665 5.83594L8.50065 2.50194L11.834 5.83594" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>}>Select Folder</Button>
                            </div>
                        </div>
                    </span>
                </div>
            </span>
            <span>
                <div className={fileList.length > 0 ? "ant-upload ant-upload-drag uploader-card-view" : "ant-upload ant-upload-drag"}
                onClick={(e:any)=>{
                    let node:any = fileUpload?.current;
                    node?.click();
                }}>
                    <span className="ant-upload ant-upload-btn" role="button">
                        <input 
                            ref={fileUpload}
                            type="file" 
                            style={{display: 'none'}} 
                            onClick={(e:any)=>e.target.value = null} 
                            onChange={handleChange}
                            // accept={`${AUDIO_SUPPORTED},${VIDEO_SUPPORTED},${WORD_SUPPORTED},${ZIP_SUPPORTED},${PRESENTATION_SUPPORTED},${IMAGE_SUPPORTED},${OTHER_SUPPORTED}`}
                            multiple />
                        <div className="ant-upload-drag-container">
                            <div className="upload-card">
                                <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M51.7468 16.1364C51.9213 15.3346 52.0097 14.5209 52.0109 13.7053C52.0002 6.13048 44.8276 -0.00268329 35.9904 0.00635764C29.263 0.013289 23.2592 3.62614 20.9664 9.04718C16.8611 7.64644 12.2082 9.36342 10.5739 12.8822C10.4325 13.1868 10.3167 13.4996 10.2275 13.8184C3.6724 14.6587 -0.846722 19.8947 0.133633 25.5133C1.01285 30.5524 6.06334 34.2805 12.0076 34.2784H22.0084C23.1131 34.2784 24.0086 33.5108 24.0086 32.5639C24.0086 31.6171 23.1131 30.8495 22.0084 30.8495H12.0076C7.5889 30.8495 4.00688 27.7792 4.00688 23.9917C4.00688 20.2043 7.5889 17.134 12.0076 17.134C13.1123 17.134 14.0078 16.3664 14.0078 15.4195C14.0114 13.5259 15.8054 11.9932 18.0146 11.9963C19.0595 11.9978 20.0622 12.3496 20.8083 12.9765C21.595 13.6411 22.8615 13.6331 23.6368 12.9588C23.928 12.7055 24.12 12.3811 24.1865 12.0301C25.2722 6.43144 31.4473 2.64731 37.9791 3.57793C44.5108 4.50854 48.9257 9.8015 47.8399 15.4001C47.7752 15.734 47.6912 16.0649 47.5884 16.3915C47.3585 17.1072 47.6919 17.8683 48.4146 18.2774C52.0964 20.3713 53.1007 24.6273 50.6576 27.7832C49.1789 29.6933 46.6846 30.8439 44.0102 30.8493H38.0097C36.905 30.8493 36.0095 31.6169 36.0095 32.5637C36.0095 33.5106 36.905 34.2782 38.0097 34.2782H44.0102C50.6381 34.2729 56.0061 29.663 55.9998 23.9819C55.9965 20.9582 54.4411 18.0886 51.7468 16.1364Z" fill="#CFD5F6"/>
                                    <path d="M41.4246 24.4941L31.4238 15.9219C31.2376 15.7619 31.0159 15.6354 30.7717 15.55C30.2824 15.3766 29.7329 15.3766 29.2436 15.55C28.9995 15.6355 28.7779 15.762 28.5915 15.9219L18.5907 24.4941C17.8233 25.1751 17.8454 26.2604 18.64 26.9182C19.4151 27.5598 20.6439 27.5598 21.4189 26.9182L28.0095 21.2726V46.2792C28.0095 47.2261 28.905 47.9936 30.0097 47.9936C31.1144 47.9936 32.0099 47.2261 32.0099 46.2792V21.2727L38.5965 26.9183C39.3911 27.5761 40.6573 27.5572 41.4247 26.8761C42.1733 26.2117 42.1733 25.1584 41.4246 24.4941Z" fill="#CFD5F6"/>
                                </svg>
                                {/* <div className="text">
                                    Drag folder into this area
                                </div>
                                <div className="text">
                                    or
                                </div> */}
                                <Button type="link" icon={<span>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.4987 2.5026L8.4987 11.8359" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.5 11.8354C14.5 13.3081 13.306 14.5021 11.8333 14.5021H5.16667C3.694 14.5021 2.5 13.3081 2.5 11.8354" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M5.16665 5.83594L8.50065 2.50194L11.834 5.83594" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>}>Select Files</Button>
                            </div>
                        </div>
                    </span>
                </div>
            </span>
            {fileList.length > 0 && <div className="ant-upload-list ant-upload-list-picture-card">
                {fileList.map((file:any) => {
                    const isExceeding:boolean = file.size > FIVE_GB;
                    const extension:string = file.name.split('.').pop().toLowerCase();
                    const hasExtension:boolean = file.name.split('.').length > 1;
                    const url:string = file.type.includes('image/') && extension !== "psd"?file.thumbUrl:getPreview(hasExtension? extension:"folder");
                    // const isNotSupported:boolean = !hasExtension? false: !`${IMAGE_SUPPORTED}${VIDEO_SUPPORTED}${AUDIO_SUPPORTED}${PRESENTATION_SUPPORTED}${ZIP_SUPPORTED}${OTHER_SUPPORTED}${WORD_SUPPORTED}`.includes(extension);
                    
                    return <div className="ant-upload-list-picture-card-container" key={file.uid}>
                        <div className="card tooltip-primary bottom" data-tip={file.name}>
                            <div className="card-body">
                                {(isExceeding)? <div className="exceeded">
                                    <span onClick={()=>removeFile(file.uid)}>
                                        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span className="danger-text">
                                        You can't transfer more than 5GB at a time
                                    </span>
                                </div> : <> 
                                    {url && <img src={url} onError={(e:any)=>{
                                        e.target.onerror = null;
                                        e.target.src=RandomPreview}}/>}
                                    <div className="overlay">
                                        <span className="close" onClick={()=>removeFile(file.uid)}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white"/>
                                            </svg>
                                        </span>
                                    </div>
                                </>}
                            </div>
                            <div className="footer w-100">
                                <p className="text">{file.name}</p>
                            </div>
                        </div>
                    </div>
                })}
            </div>}
            {/* <UploadDragger
                className={fileList.length > 0 ? "uploader-card-view" : ""}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
                // accept={`${AUDIO_SUPPORTED},${VIDEO_SUPPORTED},${WORD_SUPPORTED},${ZIP_SUPPORTED},${PRESENTATION_SUPPORTED},${IMAGE_SUPPORTED},${OTHER_SUPPORTED}`}
                multiple
                itemRender={(originNode: ReactElement, file: any, fileList?: object[]) => {
                    const isExceeding:boolean = file.size > FIVE_GB;
                    const extension:string = file.name.split('.').pop().toLowerCase();
                    const hasExtension:boolean = file.name.split('.').length > 1;
                    const url:string = file.type.includes('image/') && extension !== "psd"?file.thumbUrl:getPreview(hasExtension? extension:"folder");
                    const isNotSupported:boolean = !hasExtension? false: !`${IMAGE_SUPPORTED}${VIDEO_SUPPORTED}${AUDIO_SUPPORTED}${PRESENTATION_SUPPORTED}${ZIP_SUPPORTED}${OTHER_SUPPORTED}${WORD_SUPPORTED}`.includes(extension);

                    return (
                        <div className="card tooltip-primary bottom" data-tip={file.name}>
                            <div className="card-body">
                                {(isExceeding)? <div className="exceeded">
                                    <span onClick={()=>removeFile(file.uid)}>
                                        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span className="danger-text">
                                        You can't transfer more than 5GB at a time
                                    </span>
                                </div> : <> 
                                    {url && <Img src={url} />}
                                    <div className="overlay">
                                        <span className="close" onClick={()=>removeFile(file.uid)}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9" cy="9" r="8.5" fill="#E95432" stroke="white"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75518 9.00104L12.5781 6.17811C12.7866 5.96958 12.7866 5.63251 12.5781 5.42398C12.3696 5.21544 12.0325 5.21544 11.824 5.42398L9.00104 8.24691L6.17811 5.42398C5.96958 5.21544 5.63251 5.21544 5.42398 5.42398C5.21544 5.63251 5.21544 5.96958 5.42398 6.17811L8.24691 9.00104L5.42398 11.824C5.21544 12.0325 5.21544 12.3696 5.42398 12.5781C5.52798 12.6821 5.66451 12.7344 5.80104 12.7344C5.93758 12.7344 6.07411 12.6821 6.17811 12.5781L9.00104 9.75518L11.824 12.5781C11.928 12.6821 12.0645 12.7344 12.201 12.7344C12.3376 12.7344 12.4741 12.6821 12.5781 12.5781C12.7866 12.3696 12.7866 12.0325 12.5781 11.824L9.75518 9.00104Z" fill="white"/>
                                            </svg>
                                        </span>
                                    </div>
                                </>}
                            </div>
                            <div className="footer w-100">
                                <p className="text">{file.name}</p>
                            </div>
                        </div>
                    )
                }}
            > */}
                {/* {fileList.length === 0? <div className="upload-section">
                    <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M51.7468 16.1364C51.9213 15.3346 52.0097 14.5209 52.0109 13.7053C52.0002 6.13048 44.8276 -0.00268329 35.9904 0.00635764C29.263 0.013289 23.2592 3.62614 20.9664 9.04718C16.8611 7.64644 12.2082 9.36342 10.5739 12.8822C10.4325 13.1868 10.3167 13.4996 10.2275 13.8184C3.6724 14.6587 -0.846722 19.8947 0.133633 25.5133C1.01285 30.5524 6.06334 34.2805 12.0076 34.2784H22.0084C23.1131 34.2784 24.0086 33.5108 24.0086 32.5639C24.0086 31.6171 23.1131 30.8495 22.0084 30.8495H12.0076C7.5889 30.8495 4.00688 27.7792 4.00688 23.9917C4.00688 20.2043 7.5889 17.134 12.0076 17.134C13.1123 17.134 14.0078 16.3664 14.0078 15.4195C14.0114 13.5259 15.8054 11.9932 18.0146 11.9963C19.0595 11.9978 20.0622 12.3496 20.8083 12.9765C21.595 13.6411 22.8615 13.6331 23.6368 12.9588C23.928 12.7055 24.12 12.3811 24.1865 12.0301C25.2722 6.43144 31.4473 2.64731 37.9791 3.57793C44.5108 4.50854 48.9257 9.8015 47.8399 15.4001C47.7752 15.734 47.6912 16.0649 47.5884 16.3915C47.3585 17.1072 47.6919 17.8683 48.4146 18.2774C52.0964 20.3713 53.1007 24.6273 50.6576 27.7832C49.1789 29.6933 46.6846 30.8439 44.0102 30.8493H38.0097C36.905 30.8493 36.0095 31.6169 36.0095 32.5637C36.0095 33.5106 36.905 34.2782 38.0097 34.2782H44.0102C50.6381 34.2729 56.0061 29.663 55.9998 23.9819C55.9965 20.9582 54.4411 18.0886 51.7468 16.1364Z" fill="#CFD5F6"/>
                        <path d="M41.4246 24.4941L31.4238 15.9219C31.2376 15.7619 31.0159 15.6354 30.7717 15.55C30.2824 15.3766 29.7329 15.3766 29.2436 15.55C28.9995 15.6355 28.7779 15.762 28.5915 15.9219L18.5907 24.4941C17.8233 25.1751 17.8454 26.2604 18.64 26.9182C19.4151 27.5598 20.6439 27.5598 21.4189 26.9182L28.0095 21.2726V46.2792C28.0095 47.2261 28.905 47.9936 30.0097 47.9936C31.1144 47.9936 32.0099 47.2261 32.0099 46.2792V21.2727L38.5965 26.9183C39.3911 27.5761 40.6573 27.5572 41.4247 26.8761C42.1733 26.2117 42.1733 25.1584 41.4246 24.4941Z" fill="#CFD5F6"/>
                    </svg>
                    <div className="text">
                        Drag photos into this area
                    </div>
                    <div className="text">
                        or
                    </div>
                    <Button type="ghost" icon={<span>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.4987 2.5026L8.4987 11.8359" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.5 11.8354C14.5 13.3081 13.306 14.5021 11.8333 14.5021H5.16667C3.694 14.5021 2.5 13.3081 2.5 11.8354" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.16665 5.83594L8.50065 2.50194L11.834 5.83594" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>}>Select Files</Button>
                    <div className="text">
                        Max. size 5GB. JPG, PDF, AI, GIF and many more are supported
                    </div>
                </div>: */}
                {/* <div className="upload-card">
                    <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M51.7468 16.1364C51.9213 15.3346 52.0097 14.5209 52.0109 13.7053C52.0002 6.13048 44.8276 -0.00268329 35.9904 0.00635764C29.263 0.013289 23.2592 3.62614 20.9664 9.04718C16.8611 7.64644 12.2082 9.36342 10.5739 12.8822C10.4325 13.1868 10.3167 13.4996 10.2275 13.8184C3.6724 14.6587 -0.846722 19.8947 0.133633 25.5133C1.01285 30.5524 6.06334 34.2805 12.0076 34.2784H22.0084C23.1131 34.2784 24.0086 33.5108 24.0086 32.5639C24.0086 31.6171 23.1131 30.8495 22.0084 30.8495H12.0076C7.5889 30.8495 4.00688 27.7792 4.00688 23.9917C4.00688 20.2043 7.5889 17.134 12.0076 17.134C13.1123 17.134 14.0078 16.3664 14.0078 15.4195C14.0114 13.5259 15.8054 11.9932 18.0146 11.9963C19.0595 11.9978 20.0622 12.3496 20.8083 12.9765C21.595 13.6411 22.8615 13.6331 23.6368 12.9588C23.928 12.7055 24.12 12.3811 24.1865 12.0301C25.2722 6.43144 31.4473 2.64731 37.9791 3.57793C44.5108 4.50854 48.9257 9.8015 47.8399 15.4001C47.7752 15.734 47.6912 16.0649 47.5884 16.3915C47.3585 17.1072 47.6919 17.8683 48.4146 18.2774C52.0964 20.3713 53.1007 24.6273 50.6576 27.7832C49.1789 29.6933 46.6846 30.8439 44.0102 30.8493H38.0097C36.905 30.8493 36.0095 31.6169 36.0095 32.5637C36.0095 33.5106 36.905 34.2782 38.0097 34.2782H44.0102C50.6381 34.2729 56.0061 29.663 55.9998 23.9819C55.9965 20.9582 54.4411 18.0886 51.7468 16.1364Z" fill="#CFD5F6"/>
                        <path d="M41.4246 24.4941L31.4238 15.9219C31.2376 15.7619 31.0159 15.6354 30.7717 15.55C30.2824 15.3766 29.7329 15.3766 29.2436 15.55C28.9995 15.6355 28.7779 15.762 28.5915 15.9219L18.5907 24.4941C17.8233 25.1751 17.8454 26.2604 18.64 26.9182C19.4151 27.5598 20.6439 27.5598 21.4189 26.9182L28.0095 21.2726V46.2792C28.0095 47.2261 28.905 47.9936 30.0097 47.9936C31.1144 47.9936 32.0099 47.2261 32.0099 46.2792V21.2727L38.5965 26.9183C39.3911 27.5761 40.6573 27.5572 41.4247 26.8761C42.1733 26.2117 42.1733 25.1584 41.4246 24.4941Z" fill="#CFD5F6"/>
                    </svg> */}
                    {/* <div className="text">
                        Drag files into this area
                    </div>
                    <div className="text">
                        or
                    </div> */}
                    {/* <Button type="link" icon={<span>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.4987 2.5026L8.4987 11.8359" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.5 11.8354C14.5 13.3081 13.306 14.5021 11.8333 14.5021H5.16667C3.694 14.5021 2.5 13.3081 2.5 11.8354" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.16665 5.83594L8.50065 2.50194L11.834 5.83594" stroke="#7335CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>}>Select Files</Button>
                </div> */}
                {/* // } */}
            {/* </UploadDragger> */}
        </div>
    </Drawer>
}

export default UploadDesignsDrawer;