import React,{FC,useEffect,useMemo,useRef,useState} from 'react';
import TopBar from '../../shared/TopBar';
import { useSelector } from 'react-redux';
import { FolderState,HyperProjectFoldersState,AuthState } from '../../../store/types';
import { setLayersWithAssests,fetchLayersWithAssets,getHyperProject,setHyperProjectData,setSelectedLayerId,
  setSelectedSizeId,setSelectedAssetId,setProject,fetchHyperRenderedImages,deleteHyperRenderedImages, 
  setSelectedHpProjects,getUsersData, setBannerView, generateCombinations } from '../../../store/actions';
import {useHistory} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight,faSpinner,faDownload,faAngleDown,faCheck,faTrash } from '@fortawesome/free-solid-svg-icons';
import { openToast } from '../../../Toasts';
import { IMAGE_BASE_URL,WEBSOCKET_ENDPOINT } from '../../../utilities/paths';
import useWebSocket from '../../shared/hooks/useWebSocket';
import Menu from '../../shared/DropdownMenu';
import Export from '../../shared/Export';
import './index.scss';
import { detectQueryString } from '../../../utilities/common-function';
import BubbleContainer from '../../shared/BubbleContainer';
import Img from '../../ImageLoader/ImageLoader';
import DetailFilter from '../DetailFilter';

const DetailTopBar: FC = () => {
    const { bannerData,isBannerDetailView } = useSelector((state: {folders:FolderState})=>({
      bannerData:state.folders.bannerData,
      isBannerDetailView: state.folders.isBannerDetailView
    }));
    const state = useSelector((state: {hyperProject: HyperProjectFoldersState})=>state.hyperProject);
    const projectData = useSelector((state: {folders: FolderState})=>(state.folders.bannerData));
    const { token } = useSelector((state: { authentication: AuthState }) => state.authentication)
    const publicLink = useSelector((state: { publicLink: any }) => state.publicLink)
    const [renderData,setRenderData] = useState<any>(null);
    const history = useHistory();
    const { location: {search} } = history;
    const searchParam = new URLSearchParams(search);
    const banner = searchParam.get("banner");
    const dispatch = useDispatch();
    const exportRef = useRef(null);
    const [openExport,setOpenExport] = useState(false);
    const [deleteLoader,setDeleteLoader] = useState(false);
    const [rendering,setRendering] = useState(false);
    const [showBubble,setShowBubble] = useState(false);
    const { selectedHyperProjects,hyperProjectData,layersWithAssets,selectedAssetId,selectedLayerId,selectedSizeId } = state;
    const [client,setClientActions] = useWebSocket(`${WEBSOCKET_ENDPOINT}/realtime-updates/entity/${banner}/updates?token=${token}&type=hpproject`,renderData?.render_status === "INPROGRESS")
    const selectedLayer = useMemo(()=>selectedLayerId ? (layersWithAssets?.results||[]).find((l:any)=>l.id === selectedLayerId):null,[selectedLayerId]);
    const selectedSize = useMemo(()=>selectedSizeId ? (hyperProjectData?.sizes||[]).find((l:any)=>l.id === selectedSizeId):null,[selectedSizeId]);
    const selectedAsset = useMemo(()=>selectedAssetId ? (selectedLayer?.assets||[]).find((l:any)=>l.id === selectedAssetId):null,[selectedAssetId]);
    const isPublicLink = history.location.pathname.startsWith('/cocreate/public');
    
    useEffect(()=>{
      if(banner){
        let id:number = parseInt(banner);
        dispatch(getHyperProject({projectId: id,with_default_json: false},(res:any,err:boolean)=>{
          if(!err){
            dispatch(getUsersData({userIds: res.data.created_by},(userres:any,usererr:boolean)=>{
              if(!usererr){
                res.data.rendered_file = IMAGE_BASE_URL+res.data.rendered_file+ (detectQueryString(res.data.rendered_file)? '&q=40': '?q=40');
                res.data.created_by = userres.data.users[0].firstName+" "+userres.data.users[0].lastName;
                if(res.data.render_status = 'NOT_STARTED'){ res.data.render_status = 'INPROGRESS' } 
                dispatch(setHyperProjectData(res.data));
                setRenderData({render_status: res.data.render_status});
              }else{
                dispatch(setHyperProjectData(res.data));
              }
            }))
          }
        }))
        dispatch(fetchLayersWithAssets({project_id: id,page:1,page_size:999},(res:any,err:boolean)=>{
          if(!err){
            dispatch(setLayersWithAssests(res.data))
          }
        }))
        if(client)
        setClientActions((val:any)=>({
          ...val,
          onError: (err:any)=>{console.log(err)},
          onRecieve:(data:any)=>{
            setRenderData((d:any)=>({...d,...data}));
          }
        }))
      }
      return ()=>{
        dispatch(setHyperProjectData(null));
        dispatch(setLayersWithAssests(null));
        dispatch(setSelectedAssetId(null))
        dispatch(setSelectedLayerId(null))
        dispatch(setSelectedSizeId(null))
        if(client){ client.close() }
      }
    },[])

    useEffect(()=>{
      if(renderData?.render_status !== hyperProjectData?.render_status)
      setRenderData((r:any)=>({...r,...hyperProjectData}))
    },[hyperProjectData?.render_status])
    
    console.log(publicLink);
    return <TopBar title={<>
        {hyperProjectData?.title||""}
        {renderData?.render_status === "FAILED" && <span className="ml-10" onMouseOver={()=>setShowBubble(true)} onMouseLeave={()=>setShowBubble(false)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#D32F2F"/>
          </svg>
          {showBubble && <BubbleContainer>
            <p>
              Seems like there are some combinations which have not came out properly
            </p>
            <button className="primary" onClick={()=>{
              setRendering(true)
              dispatch(generateCombinations({ projectId: banner }, (res:any, err:boolean) => {
                  if (err) openToast('error', 'Render failed');
                  if (!err) {
                    bannerData?.banners.map((p:any)=>{
                      p.render_status = "INPROGRESS";
                    })
                    bannerData.rendered_count = 0;
                    dispatch(setProject(bannerData));
                    dispatch(setHyperProjectData({...hyperProjectData,...res.data,render_status:"INPROGRESS"}));
                    openToast('success', 'Render Successfull');
                  }
                  setRendering(false);
                  setShowBubble(false)
              }))
            }} disabled={rendering}>
              Retry
            </button>
          </BubbleContainer>}
        </span>}
      </>} 
      subtitle={renderData && bannerData?(
        renderData.render_status === "COMPLETED"? `${bannerData?.count}/${bannerData?.count} Rendered`
        : `${bannerData?.rendered_count}/${bannerData?.count} Rendered`
      ):''} 
      leftBtn={
        <div className="flex" style={{alignItems: 'center'}}>
          {!isPublicLink || (publicLink && publicLink.shareableType !== 'design') && <button className="back-btn-secondary" onClick={() => {
            if(isBannerDetailView){
              dispatch(setBannerView(false))
            }else{
              history.go(-1)
            }
          }}>
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 7V9H3.99997L9.49997 14.5L8.07997 15.92L0.159973 8L8.07997 0.0800018L9.49997 1.5L3.99997 7H16Z" fill="#20007F"/>
            </svg>
            <span className="ml-10">Back</span>
          </button>}
          {!isBannerDetailView && <DetailFilter />}
          {selectedSize && selectedLayer && selectedAsset && 
            <span className="remove-filters secondary ml-10" onClick={()=>{ 
              dispatch(setSelectedSizeId(null));
              dispatch(setSelectedLayerId(null));
              dispatch(setSelectedAssetId(null));
            }}>
              Remove Filters
            </span>
          }
        </div>
      } 
      rightBtn={!isBannerDetailView ?<>
        { !isPublicLink &&
          <button className="danger" disabled={selectedHyperProjects.length === 0}
          onClick={()=>{
            if(banner){
              setDeleteLoader(true);
              let id:number = parseInt(banner);
              dispatch(deleteHyperRenderedImages({project_id:id,ids:selectedHyperProjects},(res:any,err:boolean)=>{
                setDeleteLoader(false);
                if(!err){
                  bannerData.banners = bannerData.banners.filter((b:any)=>selectedHyperProjects.indexOf(b.id) === -1);
                  bannerData.count = bannerData.count - selectedHyperProjects.length;
                  dispatch(setProject(bannerData))
                  dispatch(setSelectedHpProjects([]))
                  openToast('success','Images Removed!')
                }
              }))
            }
          }}
        >
          {deleteLoader && <FontAwesomeIcon icon={faSpinner} />}
          <span>Discard</span>
        </button>
        }
      <button className="primary ml-10" disabled={projectData?.banners?.length === 0}
          ref={exportRef}
          onClick={()=>{
            if(renderData?.render_status === "COMPLETED"||(bannerData?.rendered_count === bannerData?.count)){
              setOpenExport(true)
            }else{
              openToast('info','Please wait for combinations to render!');
            }}}
        >
          <span className="mr-10">Download All</span>
            <svg width="13" height="12" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="white"/>
            </svg>
        </button>
        <Menu anchorRef={exportRef} open={openExport} handleClose={()=>setOpenExport(false)}>
          <div>
            <Export projectIds={[hyperProjectData?.id]} projectType="hpproject" handleClose={()=>setOpenExport(false)} />
          </div>
        </Menu>
      </>:null}
    />
}

export default DetailTopBar;