import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchMyApprovalStatus,
  getApprovalStatus,
  getUsersData,
  markApprovalStatus,
  setApprovalComments,
  setApprovalStatus,
  setIsSentForApproval,
  setStatusSummary,
} from "../../../store/actions";
import {
  ApprovalState,
  AuthState,
  FolderState,
  RolesState,
} from "../../../store/types";
import { openToast } from "../../../Toasts";
import { DeleteAlert } from "../../shared";
import ProjectDetail from "../ProjectDetail";
import ActivityBar from "../ActivityBar";
import useWebSocket from "../../shared/hooks/useWebSocket";
import { WEBSOCKET_ENDPOINT } from "../../../utilities/paths";
import { isItImageFileType } from "../../../utilities/common-function";

export const ProjectContainer: React.FunctionComponent<{
  entityType: string;
  setShowTopBar: Function;
  isPublicLink: boolean;
}> = ({ entityType, setShowTopBar, isPublicLink }) => {
  const [approvalLoader, setApprovalLoader] = useState(false);
  const [loading, setLoader] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [currentRound, setCurrentRound] = useState("");
  const [projectRename, setProjectRename] = useState("");
  const [isRejectDrawer, setIsRejectDrawer] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(-1);
  const state = useSelector(
    (state: { approval: ApprovalState }) => state.approval
  );
  const { isSentForApproval, approvalStatus } = state;
  const authState = useSelector(
    (state: { authentication: AuthState }) => state.authentication
  );
  const bannerData = useSelector(
    (state: { folders: FolderState }) => state.folders.bannerData
  );
  const { token, userData } = authState;

  const history = useHistory();
  const {
    location: { search },
  } = history;
  const searchParam = new URLSearchParams(search);
  const banner = searchParam.get("banner");
  const quick_view = searchParam.get("quick_view");
  const dispatch = useDispatch();
  const userPermission: any = useSelector((state: { roles: RolesState }) =>
    state.roles.userPermissions.length !== 0 ? state.roles.userPermissions : []
  );
  const [commentMode, setCommentMode] = useState(false);
  const [activeTab, setActiveTab] = useState(
    quick_view == "true"
      ? "2"
      : userPermission.indexOf("view-approval-and-comments") > -1
      ? "0"
      : "1"
  );
  const [activityView, setActivityView] = useState(false);
  const [commentIndex, setCommentIndex] = useState(-1);
  const [replyCommentIndex, setReplyCommentIndex] = useState(-1);
  const [client, setClientActions] = useWebSocket(
    `${WEBSOCKET_ENDPOINT}/approval-workflow/comment/${banner}-${entityType}?token=${token}`,
    true
  );
  const [retainedComments, setRetainedComments] = useState([]);
  const [prevDimensions, setPrevDimensions] = useState({
    width: 0,
    height: 0
  });
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0
  });
  const [inFocus, setInFocus] = useState(false);

  useEffect(() => {
    if(client.readyState === 1) {
      if(isItImageFileType(bannerData?.type, bannerData?.file_type)) {
        if(bannerData['render_status'] === 'COMPLETED' && bannerData?.banners[selectedBanner] && bannerData?.banners[selectedBanner]['render_status'] === 'COMPLETED') {
          if(retainedComments?.length > 0) {
            retainedComments.map((retainedComment:any) => {
              if(retainedComment.pin_number) {
                if(prevDimensions?.width && prevDimensions?.height) {
                  let widthRatio:number = (bannerData?.banners[selectedBanner]?.width) / prevDimensions?.width;
                  let heightRatio:number = (bannerData?.banners[selectedBanner]?.height) / prevDimensions?.height;
                  retainedComment.xcoordinate *= widthRatio;
                  retainedComment.ycoordinate *= heightRatio;
                }
              }
              client.send(
                JSON.stringify({
                  comment_id: retainedComment.id,
                  child_id: retainedComment.child_id,
                  xcoordinate: retainedComment.xcoordinate,
                  ycoordinate: retainedComment.ycoordinate,
                  action: "child_id_updated"
                })
              );
            });
          }
        } 
      } else {
        if(retainedComments?.length > 0) {
          retainedComments.map((retainedComment:any) => {
            client.send(
              JSON.stringify({
                comment_id: retainedComment.id,
                child_id: retainedComment.child_id,
                xcoordinate: retainedComment.xcoordinate,
                ycoordinate: retainedComment.ycoordinate,
                action: "child_id_updated"
              })
            );
          });
        }
      }
    }
  }, [retainedComments, bannerData, client]);

  useEffect(() => {
    if (bannerData) {
      setActivityView(true);
    }
  }, [bannerData]);

  useEffect(() => {
    if (banner && userData) {
      fetchStatus(true);
      let entityId: number = parseInt(banner as string);
      // check whether current user is approver or not
      dispatch(
        fetchMyApprovalStatus(
          {
            entity_id: entityId,
            user_id: userData.userId,
            entity_type: entityType,
          },
          (res: any, err: boolean) => {
            if (!err) {
              // value of enabledecisionbox is set from backend
              if (res.data.enabledecisionbox) {
                setIsApprover(true);
              }
            }
          }
        )
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setApprovalStatus(null));
      dispatch(setStatusSummary(null));
      dispatch(setIsSentForApproval(false));
      dispatch(setApprovalComments([]));
    };
  }, []);

  // Fetch all approval info
  const fetchStatus = (displayLoader: boolean) => {
    if (banner) {
      let id: number = parseInt(banner);
      displayLoader && setLoader(true);
      displayLoader && setApprovalLoader(true);
      dispatch(
        getApprovalStatus(
          { entity_id: id, entity_type: entityType },
          (response: any, error: boolean) => {
            let { data } = response;
            if (!error) {
              dispatch(setIsSentForApproval(true));
              displayLoader && setApprovalLoader(false);
              let ids: string[] = data.rounds.reduce((acc: any, val: any) => {
                return [
                  ...acc,
                  ...val.approvers.reduce((acc1: any, val1: any) => {
                    return [...acc1, val1.approver_id];
                  }, []),
                ];
              }, []);
              if (ids.length > 0) {
                dispatch(
                  getUsersData({ userIds: ids }, (res: any, err: boolean) => {
                    if (!err) {
                      let { users } = res.data;
                      data.rounds.map((round: any, index: number) => {
                        if (round.id === data.current_round) {
                          setCurrentRound(round.name);
                        }
                        round.approvers.map((a: any, r: number) => {
                          a.approver_id =
                            users.find((u: any) => u._id === a.approver_id) ||
                            "";
                        });
                      });
                      setLoader(false);
                      dispatch(setApprovalStatus(data));
                    }
                  })
                );
              } else {
                setLoader(false);
                dispatch(setApprovalStatus(data));
              }
            } else {
              setLoader(false);
              displayLoader && setApprovalLoader(false);
              dispatch(setIsSentForApproval(false));
            }
          }
        )
      );
    }
  };

  const handleStatusChange = useCallback(
    (
      status: string,
      setDrawerLoader?: Function,
      setDrawerVisible?: Function
    ) => {
      let entityId = banner ? parseInt(banner) : 0;
      if (userData) {
        setStatusLoader(true);
        dispatch(
          markApprovalStatus(
            {
              userId: userData.userId,
              entity_id: entityId,
              status,
              entity_type: entityType,
            },
            (res: any, err: boolean) => {
              if (!err) {
                dispatch(
                  fetchMyApprovalStatus(
                    {
                      entity_id: entityId,
                      user_id: userData.userId,
                      entity_type: entityType,
                    },
                    (res: any, err: boolean) => {
                      if (!err) {
                        // value of enabledecisionbox is set from backend
                        if (res.data.enabledecisionbox) {
                          setIsApprover(true);
                        } else {
                          setIsApprover(false);
                        }
                      } else {
                        setIsApprover(false);
                      }
                    }
                  )
                );
                openToast("success", "Status Changed!");
                fetchStatus(false);
                setStatusLoader(false);
                // In case of reject artwork
                setDrawerLoader && setDrawerLoader(false);
                setDrawerVisible && setDrawerVisible(false);
              } else {
                openToast(
                  "warn",
                  res.data.message || "Issue while changing status!"
                );
              }
              setStatusLoader(false);
              // In case of reject artwork
              setDrawerLoader && setDrawerLoader(false);
              setDrawerVisible && setDrawerVisible(false);
            }
          )
        );
      }
    },
    []
  );

  return (
    <>
      <ProjectDetail
        approvalLoader={approvalLoader}
        type={entityType}
        isSentForApproval={isSentForApproval}
        fetchStatus={fetchStatus}
        handleStatusChange={handleStatusChange}
        isApprover={isApprover}
        setIsApprover={setIsApprover}
        statusLoader={statusLoader}
        currentRound={currentRound}
        approvalStatus={approvalStatus}
        setActiveTab={setActiveTab}
        setIsRejectDrawer={setIsRejectDrawer}
        projectRename={projectRename}
        setProjectRename={setProjectRename}
        setShowTopBar={setShowTopBar}
        setSelectedBanner={setSelectedBanner}
        selectedBanner={selectedBanner}
        commentMode={commentMode}
        setCommentMode={setCommentMode}
        commentIndex={commentIndex}
        setCommentIndex={setCommentIndex}
        setReplyCommentIndex={setReplyCommentIndex}
        setRetainedComments={setRetainedComments}
        imageDimensions={imageDimensions}
        setImageDimensions={setImageDimensions}
        setPrevDimensions={setPrevDimensions}
        commentClient={client}
        inFocus={inFocus}
        setInFocus={setInFocus}
      />
      {!isPublicLink && activityView && (
        <ActivityBar
          entityType={entityType}
          isSentForApproval={isSentForApproval}
          approvalLoader={approvalLoader}
          fetchStatus={fetchStatus}
          loading={loading}
          handleStatusChange={handleStatusChange}
          activeTab={activeTab}
          setIsApprover={setIsApprover}
          setIsRejectDrawer={setIsRejectDrawer}
          projectRename={projectRename}
          userPermission={userPermission}
          setActiveTab={setActiveTab}
          isPublicLink={isPublicLink}
          setSelectedBanner={setSelectedBanner}
          selectedBanner={selectedBanner}
          setCommentMode={setCommentMode}
          userId={userData.userId}
          entity_id={banner}
          commentIndex={commentIndex}
          setCommentIndex={setCommentIndex}
          replyCommentIndex={replyCommentIndex}
          setReplyCommentIndex={setReplyCommentIndex}
          retainedComments={retainedComments}
          client={client}
          setClientActions={setClientActions}
          setInFocus={setInFocus}
        />
      )}
      {isRejectDrawer && (
        <DeleteAlert
          title="Reject current artwork?"
          subTitle="Are you sure you want to reject current artwork?"
          isDeleteDrawerVisible={isRejectDrawer}
          setDrawerVisible={setIsRejectDrawer}
          componentCaller="rejectArtwork"
          handleStatusChange={handleStatusChange}
        />
      )}
    </>
  );
};
