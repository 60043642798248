import React from "react";

export const SideSelect = ({ data, product = "", sideSelectData, setSideSelectData }) => {
    const { title, items } = data;
    const handleSelect = (item: any, title) => {

        setSideSelectData((prev: any) => {
            return {
                ...prev,
                [title]: item.label
            }
        })
    }
    console.log(product)
    return (
        <div className="side-select-container">
            <div className="field-title">
                {title}
            </div>
            <div className="side-select">
                {items.map((item, index) => <div style={{ color: product == 'HOME_LOAN' && (item.label == 'Male' || item.label == 'Female') ? 'grey' : 'black', pointerEvents: product == 'HOME_LOAN' && (item.label == 'Male' || item.label == 'Female') ? 'none' : 'inherit' }} className={sideSelectData[title] == item.label ? `side-select-card selected` : `side-select-card`}
                    onClick={() => handleSelect(item, title)}
                    key={index}>{item.label}</div>)}
            </div>
        </div>
    )
}