import { Radio, Space } from 'antd';
import React, { useEffect } from 'react';
import MustContain from './MustContainPopup';
function ModePopup(props) {
    const ref = React.useRef<any>(null);
    const [popups, setPopups] = React.useState<any>({
        "Festivals": false,
        "Main Shopping days": false,
        "Other public holidays": false,
        "Special Occasions": false
    });
    const { onClickOutside } = props;
    const [selectedWords, setSelectedWords] = React.useState<any>({
        "Festivals": '',
        "Main Shopping days": '',
        "Other public holidays": '',
        "Special Occasions": ''
    });
    const MustContainItems: any = [['Festivals', ['Diwali', 'Holi', 'Chinese New Year', 'Thanksgiving', 'Easter']], ['Main Shopping days', []], ['Other public holidays', []], ['Special Occasions', []]];
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return (
        <div ref={ref} className="mustcontain-popup">
            <div>Must contain</div>
            {MustContainItems.map((item) => {
                const [title, options] = item;
                return (
                    <div className="mustcontain-category">
                        <div className="mustcontain-category-title">{item[0]}</div>
                        <div className="mode-input-container" style={{ display: 'block' }} >
                            <div className="mode-input" onClick={(e) => { setPopups({ ...popups, [title]: true }); e.stopPropagation(); console.log(popups) }}>
                                {/* <span>Click here to choose</span> */}
                                <span>{selectedWords[item[0]] ? selectedWords[item[0]] : 'Click here to choose'}</span>
                                <span><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                            </div>
                            {
                                popups[title] &&
                                <MustContain
                                    selectedWords={selectedWords[title]}
                                    title={item[0]}
                                    setSelectedWords={(value) => { setSelectedWords({ ...selectedWords, [title]: value }) }}
                                    options={item[1]}
                                    show={popups[title]}
                                    onClickOutside={() => { setPopups({ ...popups, [title]: false }) }} />

                            }
                        </div>
                    </div>

                )
            })}

        </div>
    )
}

export default ModePopup;