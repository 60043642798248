import React, { Dispatch, FC,useCallback,useEffect,useRef,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExportState,AuthState } from "../../../../store/types";
import { downloadFile } from "../../../../utilities/common-function";
import { IMAGE_BASE_URL, S3_BASE_URL, WEBSOCKET_ENDPOINT } from "../../../../utilities/paths";
import { setProjectsForReadyCheck } from "../../../../store/actions";
import useWebSocket from "../../hooks/useWebSocket";
import "../index.scss";
import iconComplete from '../../../../assets/svg/iconComplete.svg'
import { CloseCircleOutlined, LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Collapse, CollapsePanel, Spin,Tooltip } from "pyxis-ui-kit";
import { setExportedProjects } from "../../../../store/actions/export";
import { useHistory } from "react-router-dom";
import { IMAGE_SUPPORTED } from "../../../../utilities/contants";
const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
let timer:any;
const imigixFormats = ['svg', 'png', 'gif', 'jpeg', 'jpg' ];
const ProjectItem:FC<{
    data: any;
    index: number;
    handleUpdate:Function
}> = ({data,index,handleUpdate}) => {
    const [exportData,setExportData] = useState(data);
    const history = useHistory();
    const isPublicLink = history.location.pathname.startsWith('/cocreate/public');
    const token = useSelector((state:{ authentication:AuthState })=>state.authentication.token);
    const publicLink = useSelector((state: { publicLink: any }) => state.publicLink);
    const [client, setClientActions] = useWebSocket(`${WEBSOCKET_ENDPOINT}/realtime-updates/export/${data.id}/updates?token=${!isPublicLink ? token : publicLink.token}`, (data.status !== "COMPLETED" && data.status !== "FAILED"));

    useEffect(()=>{
        if(client){
            setClientActions((val:any)=>({
                ...val,
                onError:(err:any)=>{console.error('error',err)},
                onRecieve:(data:any)=>{
                    setExportData(data);
                    if(data.status === "COMPLETED"||data.status === "FAILED"){
                        handleUpdate(index,data)
                    }
                }
            }))
        }
        
        return ()=>{
            if(client){ client.close() }
        }
    },[])
 
    useEffect(()=>{
        if((exportData.status === "COMPLETED"||exportData.status === "FAILED") && client){
            client.close()
        }
    },[exportData.status])

    return <div className="item" key={index}>
        <div className="detail">
            <div className="about">
                <span className="thumbnail">
                    <svg width="48" height="48" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.1" cx="20" cy="20" r="20" fill="#9E54FD"/>
                        <path d="M22 14L18.25 19L21.1 22.8L19.5 24C17.81 21.75 15 18 15 18L9 26H31L22 14Z" fill="#9E54FD"/>
                    </svg>
                    
                </span>
                <div className="flex-column">
                    <>
                        {exportData.name ?
                        <Tooltip
                        overlayStyle={{fontWeight:"normal"}}
                        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                        placement="top" title={exportData.name+"."+exportData.output_format} content={
                            <p className="name">{exportData.name+"."+exportData.output_format}</p>}>
                        </Tooltip>
                            :<p className="name">{exportData.status === "FAILED"?"Unable to download": 'downloading...'}</p>}
                        {exportData.progress === 100 ? <span>
                            {exportData.status !== "FAILED" && <a href={`${imigixFormats.includes(exportData.output_format) ? IMAGE_BASE_URL : S3_BASE_URL}${exportData.link}`} target="_blank" download={exportData.name}>Download</a>}
                        </span>:
                            <span className="progress">{exportData.status === "FAILED"? "Failed": exportData.progress+"%"}</span>
                        }
                    </>
                </div>
            </div>
            {data.progress === 100 && data.status === "INPROGRESS" && <span>
                <Spin indicator={antIcon} />
            </span>} 
            {data.status === "FAILED" && <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 13H11V7H13V13ZM13 17H11V15H13V17ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z" fill="#D32F2F"/>
                </svg>
            </span>}
            {(data.progress === 100 && data.status === "COMPLETED" && data.id) && data.status !== "FAILED" && <span>
                <img src={iconComplete}/>
            </span>}
        </div>
    </div>
}

const CollapseHeader: FC<{
    isMinimized: boolean,
    dispatch:Dispatch<any>
}> = ({isMinimized,dispatch}) => {
    return <div>
        <div className="header_container">
            <span className="header">Downloads</span>
            <span className="actions">
                {isMinimized?<span className="minimize">Maximize <PlusCircleOutlined className="icon"/></span>
                :<span className="minimize">Minimize <MinusCircleOutlined className="icon"/></span>}
                <span className="minimize close" onClick={()=>{
                    dispatch(setExportedProjects([]))
                }}>Close<CloseCircleOutlined className="icon"/></span>
            </span>
        </div>
    </div>
}

const DownloadPopUp: FC = () => {
    const { exportedProjects } = useSelector((state: {export:ExportState})=>(state.export));
    const [isMinimized, setIsMinimized] = useState(true);
    const dispatch = useDispatch();
    const downloadListRef = useRef(null);
    const { isLoggedIn } = useSelector((state: { authentication: AuthState }) => state.authentication ? state.authentication : { isLoggedIn: null });
    const publicLinkUser = useSelector((state: { publicLink: any }) => state.publicLink);

    const handleUpdate = useCallback((index:number,updatedData:any)=>{
            exportedProjects[index] = updatedData;
            // checkForPopUpClose(exportedProjects);
            dispatch(setExportedProjects(exportedProjects))
            if(updatedData.status === "COMPLETED")
            downloadFile(`${imigixFormats.includes(updatedData.exportFormat)? IMAGE_BASE_URL: S3_BASE_URL}${updatedData.link}`,updatedData.link)
            // if(updatedData
    },[exportedProjects])

    const checkForPopUpClose = useCallback((projects:any[])=>{
        if(projects.length > 0 && !isMinimized){
            const completedCount:number = projects.reduce((acc:any,val:any)=>{
                if(val.ready_status === "COMPLETED"){
                    return acc+1;
                }
                return acc;
            },0)
            
            if(completedCount === projects.length){
                timer =  setTimeout(()=>dispatch(setProjectsForReadyCheck([])),5000)
            }
            return completedCount === projects.length
        }
    },[isMinimized])

    useEffect(()=>{
        if(isMinimized && timer){
            clearTimeout(timer);
        }else{
            if(!isMinimized && checkForPopUpClose(exportedProjects)){
                timer = setTimeout(()=>dispatch(setExportedProjects([])),5000)
            }
        }
        
    },[exportedProjects,isMinimized])

    useEffect(()=>{
        let node:any = downloadListRef?.current;
        if(timer){
            clearTimeout(timer);
        }
        if(isMinimized){
            setIsMinimized(false)
        }
        if(node){
            node.scrollIntoView({ behavior: "smooth" })
        }
    },[exportedProjects])

    return (exportedProjects.length>0 && (isLoggedIn || publicLinkUser) ?
        // collapse is minimized and maximized also from activekey
        <Collapse activeKey={[isMinimized?'0':'1']} onChange={() => setIsMinimized(prev => !prev)}>
            <CollapsePanel 
                header={<CollapseHeader 
                        isMinimized={isMinimized} 
                        dispatch={dispatch}
                        />}
                key="1"
                showArrow={false}
                >
            {exportedProjects.map((item: any, index: number) => (
                <ProjectItem key={item?.title+index} data={item} index={index} handleUpdate={handleUpdate} />
            ))}
            <div style={{ float:"left", clear: "both" }}
                ref={downloadListRef}>
            </div>
            </CollapsePanel>
        </Collapse>
    :null);
};

export default DownloadPopUp;