import { Space, Input, Button } from 'pyxis-ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import DateFilter from './Filters/DateFilter';
import StatusFilter from './Filters/StatusFilter';
import UserFilter from './Filters/UserFilter';
import ProjectListing from './ProjectListing';
import './index.scss';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import TagsFilter from './Filters/TagsFilter';
import SortedByFilter from './Filters/SortByFilter';
import PublicLinkDrawer from '../Public/PublickLinkDrawer';

const searchInitialValue:string = '';
const createdRangeInitialValue:string = 'All Time';
const approvedRangeInitialValue:string = 'All Time';
const sortedByInitialValue:string = 'Date Created';
const approvalStatusInitialValue:string[] = [];
const createdByInitialValue:string[] = [];
const approvedByInitialValue:string[] = [];
const tagsInitialValue:string[] = [];

const Title:React.FC = ({children}) => <div className="title">{children}</div>;

const CreativeFilters:React.FC = () => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const [filter,setFilter] = useState<any>({...(params.get('q')? {search:params.get('q'),order_by: 'relevance'}:{order_by: '-created_at'})});
    const [searchValue,setSearchValue] = useState<string>(params.get('q')||searchInitialValue);
    const [createdRangeValue,setCreatedRangeValue] = useState<string>(createdRangeInitialValue);
    const [approvedRangeValue,setApprovedRangeValue] = useState<string>(approvedRangeInitialValue);
    const [approvalStatusValue,setApprovalStatusValue] = useState<string[]>(approvalStatusInitialValue);
    const [createdByValue,setCreatedByValue] = useState<string[]>(createdByInitialValue);
    const [approvedByValue,setApprovedByValue] = useState<string[]>(approvedByInitialValue);
    const [tagsValue,setTagsValue] = useState<string[]>(tagsInitialValue);
    const [sortedByValue,setSortedByValue] = useState<string>(params.get('q')? 'Relevance': sortedByInitialValue);
    const [projectType,setProjectType] = useState<string>(params.get('project_type')||'project');
    const [publicLinkDrawerProps, setPublicLinkDrawerProps] = useState<any>(null);

    const updateFilter = useCallback((updatedFilter:any) => {
        setFilter((f:any)=>({...f,...updatedFilter}));
    },[setFilter]);

    const changeSearchFilter = useCallback((e:any)=>{
        if(!searchValue && !filter['search']) return;
        if(searchValue && filter['search'] === searchValue) return;
        if(!searchValue){
            setCreatedRangeValue(createdRangeInitialValue);
            setFilter((f:any)=>(omit(f,['search'])));
        }else if(searchValue){
            changeSortedByFilter({ value: 'Relevance',sortedBy: 'relevance' })
            updateFilter({ search: searchValue });
        }
    },[updateFilter,setFilter,filter,searchValue]);

    const changeCreatedRangeFilter = useCallback((props:any)=>{
        if(!props && !filter['created_at_start'] && !filter['created_at_end']) return;
        if(!props){
            setCreatedRangeValue(createdRangeInitialValue);
            setFilter((f:any)=>(omit(f,['created_at_start','created_at_end'])));
        }else if(props){
            let { start,end,value } = props;
            setCreatedRangeValue(value);
            updateFilter({ created_at_start:start,created_at_end:end });
        }
    },[updateFilter,setFilter,filter]);

    const changeApprovedRangeFilter = useCallback((props:any)=>{
        if(!props && !filter['approved_at_start'] && !filter['approved_at_end']) return;
        if(!props){
            setApprovedRangeValue(approvedRangeInitialValue);
            setFilter((f:any)=>(omit(f,['approved_at_start','approved_at_end'])));
        }else if(props){
            let { start,end,value } = props;
            setApprovedRangeValue(value);
            updateFilter({ approved_at_start:start,approved_at_end:end });
        }
    },[updateFilter,setFilter,filter]);

    const changeApprovalStatusFilter = useCallback((props:any)=>{
        if(!props && !filter['statuses']) return;
        if(!props){
            setApprovalStatusValue(approvalStatusInitialValue);
            setFilter((f:any)=>(omit(f,['statuses'])));
        }else if(props){
            let { status,checked,value } = props;
            if(checked){
                setApprovalStatusValue((v:string[])=>([...v,value]));
                updateFilter({ statuses: [...filter['statuses']||[],status] });
            }else{
                setApprovalStatusValue((v:string[])=>( v.filter((text:string)=>text !== value) ));
                updateFilter({ statuses: (filter['statuses']||[]).filter((f:string)=>f !== status) });
            }
        }
    },[updateFilter,setFilter,filter]);
    
    const changeCreatedByFilter = useCallback((props:any,isRawUpdate:boolean = false)=>{
        if(!props && !filter['created_by']) return;
        if(isRawUpdate){
            if(props){
                updateFilter({ created_by: props });
            }else{
                setCreatedByValue(createdByInitialValue);
                setFilter((f:any)=>(omit(f,['created_by'])));
            }
            return;
        }
        if(props){
            let { userId,checked,value } = props;
            if(checked){
                setCreatedByValue((v:string[])=>([...v,value]));
                updateFilter({ created_by: [...filter['created_by']||[],userId] });
            }else{
                if(filter['created_by'].length === 1){
                    setCreatedByValue(createdByInitialValue);
                    setFilter((f:any)=>(omit(f,['created_by'])));
                }else{
                    setCreatedByValue((v:string[])=>( v.filter((text:string)=>text !== value) ));
                    updateFilter({ created_by: (filter['created_by']||[]).filter((f:string)=>f !== userId) });
                }
            }
        }
    },[updateFilter,setFilter,filter]);

    const changeApprovedByFilter = useCallback((props:any,isRawUpdate:boolean = false)=>{
        if(!props && !filter['approved_by']) return;
        if(isRawUpdate){
            if(props){
                updateFilter({ approved_by: props });
            }else{
                setApprovedByValue(approvedByInitialValue);
                setFilter((f:any)=>(omit(f,['approved_by'])));
            }
            return;
        }
        if(props){
            let { userId,checked,value } = props;
            if(checked){
                setApprovedByValue((v:string[])=>([...v,value]));
                updateFilter({ approved_by: [...filter['approved_by']||[],userId] });
            }else{
                if(filter['approved_by'].length === 1){
                    setApprovedByValue(approvedByInitialValue);
                    setFilter((f:any)=>(omit(f,['approved_by'])));
                }else{
                    setApprovedByValue((v:string[])=>( v.filter((text:string)=>text !== value) ));
                    updateFilter({ approved_by: (filter['approved_by']||[]).filter((f:string)=>f !== userId) });
                }
            }
        }
    },[updateFilter,setFilter,filter]);

    const changeTagsFilter = useCallback((props)=>{
        if(!props && !filter['tags']) return;
        if(!props){
            setTagsValue(tagsInitialValue);
            setFilter((f:any)=>(omit(f,['tags'])));
        }else if(props){
            setTagsValue(props);
            updateFilter({ tags:props });
        }
    },[updateFilter,setFilter,filter])

    const changeSortedByFilter = useCallback((props)=>{
        if(!props && !filter['order_by']) return;
        let { value,sortedBy } = props;
        if(props && filter['order_by'] === sortedBy) return;
        setSortedByValue(value);
        updateFilter({ order_by: sortedBy });
    },[updateFilter,setFilter,filter])

    useEffect(()=>{
        if(!searchValue && filter['search']){
            changeSearchFilter(null)
            changeSortedByFilter({ value: 'Date Created',sortedBy: '-created_at' })
        }
    },[searchValue])
    
    return <div className="creative-filters">
        <Button className="back-btn-secondary" onClick={()=>{
                history.go(-1)
            }}>
            <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.0002 7.00008V9.00008H4.00016L9.50016 14.5001L8.08016 15.9201L0.160156 8.00008L8.08016 0.0800781L9.50016 1.50008L4.00016 7.00008H16.0002Z" fill="#7335cb"/>
            </svg>
            <span className="ml-10">Back</span>
        </Button>
        <Input className="search" value={searchValue} placeholder="Search Designs.." allowClear bordered={false}
            suffix={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.6829 17.6371C1.1057 14.0599 1.1057 8.2601 4.6829 4.6829C8.2601 1.1057 14.0599 1.1057 17.6371 4.6829C20.9069 7.95265 21.1879 13.0794 18.4802 16.6683L22.0943 20.2825C23.0371 21.2253 21.6229 22.6395 20.6801 21.6967L17.1092 18.1257C13.5103 21.2061 8.08905 21.0432 4.6829 17.6371ZM16.2229 6.09712C13.4267 3.30096 8.89327 3.30096 6.09712 6.09712C3.30096 8.89327 3.30096 13.4267 6.09712 16.2229C8.89327 19.019 13.4267 19.019 16.2229 16.2229C19.019 13.4267 19.019 8.89327 16.2229 6.09712Z" fill="#152370"/>
            </svg>}
            onChange={(e:any)=>{setSearchValue(e.target.value)}} 
            onPressEnter={changeSearchFilter}
            onBlur={changeSearchFilter}
            autoFocus
        />
        <Title>Filters</Title>
        <Space size="large" className="space-wrap">
            <DateFilter label="Created date range" changeFilter={changeCreatedRangeFilter} dateFormat='YYYY-MM-DD' value={createdRangeValue} />
            <UserFilter label="Created by" selectedUsers={filter['created_by']||[]} changeFilter={changeCreatedByFilter} permissions={['create-new-banners']} value={createdByValue} />
            <DateFilter label="Approval date range" changeFilter={changeApprovedRangeFilter} dateFormat='YYYY-MM-DD' value={approvedRangeValue} />
            <UserFilter label="Approved by" selectedUsers={filter['approved_by']||[]} changeFilter={changeApprovedByFilter} permissions={['grand-approval']} value={approvedByValue} />
            <StatusFilter label="Approval status" changeFilter={changeApprovalStatusFilter} value={approvalStatusValue} />
            <TagsFilter label="Tags" changeFilter={changeTagsFilter} value={tagsValue} projectType={projectType} />
        </Space>
        <Space className="w-100" direction="horizontal" style={{justifyContent:'space-between'}}>
            <Title>Search Results</Title>
            <SortedByFilter changeFilter={changeSortedByFilter} value={sortedByValue} selectedSortBy={filter['order_by']} />
        </Space>
        <ProjectListing type={projectType} filter={filter} setPublicLinkDrawerProps={setPublicLinkDrawerProps} />
        {publicLinkDrawerProps && <PublicLinkDrawer {...publicLinkDrawerProps} handleClose={()=>setPublicLinkDrawerProps(null)} />}
    </div>
}

export default CreativeFilters;