import { UserData } from './authentication';
import { type } from 'os';
export const FETCH_FOLDERS = "FETCH_FOLDERS";
export const SET_FOLDERS = "SET_FOLDERS";
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECT = "FETCH_PROJECT";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_ACTIVE_FOLDER = "SET_ACTIVE_FOLDER";
export const ADD_NEW_FOLDER = "ADD_NEW_FOLDER";
export const ADD_FOLDER = "ADD_FOLDER";
export const ADD_SECLECTED_FOLDER = "ADD_SECLECTED_FOLDER";
export const SET_SELECTED_FOLDER = "SET_SELECTED_FOLDER";
export const MOVE_FOLDER = "MOVE_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const SET_FOLDER_POPUP_PROPS = "SET_FOLDER_POPUP_PROPS";
export const SET_BANNER_VIEW = "SET_BANNER_VIEW";
export const SET_BANNER_DATA = "SET_BANNER_DATA";
export const SET_SELECTED_PROJECTS = "SET_SELECTED_PROJECTS";
export const DELETE_PROJECTS = "DELETE_PROJECTS";
export const MOVE_PROJECTS = "MOVE_PROJECTS";
export const FETCH_FILES = "FETCH_FILES";
export const MOVE_FILES = "MOVE_FILES";
export const DELETE_FILES = "DELETE_FILES";
export const ADD_PROJECT_TO_TEMPLATE = "ADD_PROJECT_TO_TEMPLATE";
export const UPLOAD_LIBRARY_FILE = "UPLOAD_LIBRARY_FILE";
export const FETCH_LIBRARY_FILE = "FETCH_LIBRARY_FILE";
export const SET_FOLDERS_PAGE_DATA = "SET_FOLDERS_PAGE_DATA";
export const SET_FOLDER_LOADER = "SET_FOLDER_LOADER";
export const FETCH_HYPER_PROJECTS = "FETCH_HYPER_PROJECTS";
export const FETCH_ALL_FILES = "FETCH_ALL_FILES";
export const SET_ACTIVE_FOLDER_CREATE = 'SET_ACTIVE_FOLDER_CREATE';
export const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';
export const SET_PROJECT_LOADER = 'SET_PROJECT_LOADER';
export const FETCH_TAGS = 'FETCH_TAGS';
export const SET_FILTER = 'SET_FILTER';
export const GENERATE_SHAREABLE_LINK = 'GENERATE_SHAREABLE_LINK';
export const FETCH_PROJECT_FROM_SHAREABLE_LINK = 'FETCH_PROJECT_FROM_SHAREABLE_LINK';
export const DELETE_SHAREABLE_LINK = 'DELETE_SHAREABLE_LINK';
export const GET_SHAREABLE_LINK = 'GET_SHAREABLE_LINK';
export const GET_TOKEN_FROM_SHAREABLE_LINK = 'GET_TOKEN_FROM_SHAREABLE_LINK';
export const SEARCH_TAGS = 'SEARCH_TAGS';
export const ADD_OR_DELETE_TAG = 'ADD_OR_DELETE_TAG';
export const FILE_TO_PROJECT = 'FILE_TO_PROJECT';
export const UPDATE_FILE_TO_PROJECT = 'UPDATE_FILE_TO_PROJECT';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const CREATE_VARIANT = 'CREATE_VARIANT';
export const DELETE_VARIANT = 'DELETE_VARIANT';
export const CANCEL_FILE_TO_PROJECT = 'CANCEL_FILE_TO_PROJECT';
export const RESET_FILE_TO_PROJECT = 'RESET_FILE_TO_PROJECT';
export const FILE_UPLOADER = 'FILE_UPLOADER';
export const UPLOAD_TO_S3 = 'UPLOAD_TO_S3';
export const PROJECT_UPLOAD = 'PROJECT_UPLOAD';
export const SET_UPLOADED_BANNERS = 'SET_UPLOADED_BANNERS';
export const SET_FILE_TO_PROJECT_QUEUE = 'SET_FILE_TO_PROJECT_QUEUE';
export const SET_FOLDER_BREADCRUMB = 'SET_FOLDER_BREADCRUMB';
export const CREATE_FOLDER_STRUCTURE = 'CREATE_FOLDER_STRUCTURE';
export const GET_QUICK_VIEW_BUCKET = 'GET_QUICK_VIEW_BUCKET';
export const SET_UPLOADED_FOLDER = 'SET_UPLOADED_FOLDER';
export const SET_UPLOAD_FILE_LIST = 'SET_UPLOAD_FILE_LIST';
export const SET_FOLDER_CONTENT_COUNT = 'SET_FOLDER_CONTENT_COUNT';
export const SET_TEMP_FOLDER_CONTENT_COUNT = 'SET_TEMP_FOLDER_CONTENT_COUNT';
export const SET_CURRENT_FOLDER_FILE = 'SET_CURRENT_FOLDER_FILE';
export const UPDATE_PROJECT_DESCRIPTION = 'UPDATE_PROJECT_DESCRIPTION';
export const SEARCH_META = 'SEARCH_META';
export const ADD_META = 'ADD_META';
export const DELETE_META = 'DELETE_META';
export const FETCH_DESIGN_META = 'FETCH_DESIGN_META';


export type FolderState = {
    folders: Folder[],
    banners: Banner[],
    isBannerDetailView: boolean,
    bannerData: Project | null | any,
    activeFolderId: number,
    activeFolderIdCreate: number,
    selectedFolders: number[],
    selectedProjects: number[],
    pageData: any,
    folderLoading: boolean,
    projectLoading: boolean,
    filter: any,
    uploadedBanners: Banner[],
    fileToProjectQueue: any,
    folderBreadcrumb: Folder[],
    uploadedFolder: Folder[],
    currentFolderFile: number,
    uploadFileList: any[],
    folderContentCount: {},
    tempFolderContentCount: {}
}

export type Folder = {
    id: number,
    name: string,
    type: string,
    parent: number,
    content_count: number,
    subfolder_count: number
}

export type BannerSize = {
    height: number,
    width: number,
    name: string,
    type: string
}

export type Banner = {
    id: number,
    title: string,
    type: string,
    parent: number,
    rendered_file: string,
    created_by: string,
    created_at: string,
    can_manage: boolean,
    status?: string,
    render_status?: string,
    ready_status?: string,
    ready_failure_reason?: string,
    ready_progress?: number,
    rendering_count?: number,
    rendered_count?: number,
    total_count?: number,
    percentage_completion?: number,
    entity_id?: string,
    file_type?: string,
    sizes?: BannerSize[],
    psdUrl?: string,
}

export type BannerData = {
    id: number,
    rendered_file: string,
    title: string,
}

export type Project = {
    created_at: string,
    created_by: string,
    description: string,
    id: number,
    modified_at: string,
    template: string,
    title: string,
    type: string,
    rendered_file: string,
    can_manage: boolean,
    render_status: string,
    ready_status?: string,
    ready_failure_reason?: string,
    ready_progress?: number,
    banners: any[],
    auto_tags: string[] | null,
    tags: string[] | null,
    file_type?: string
}

export type FolderQuery = {
    parent?: number[],
    type: string,
    product_id: string
}

export type FetchFolders = {
    type: typeof FETCH_FOLDERS,
    payload: FolderQuery,
    callback?: Function
}

export type FetchProjects = {
    type: typeof FETCH_PROJECTS,
    payload: Banner[]
}

export type FetchProjectsQuery = {
    project_id?: number[] | number,
    folder_id?: number,
    page: number,
    page_size: number,
    product_id: number,
    created_at_start?: string,
    created_at_end?: string
    approved_at_start?: string,
    approved_at_end?: string,
    statuses?: string[],
    search?: string,
    created_by?: string[],
    approved_by?: string[],
    tags?: string[],
    order_by?: string
}

export type FetchProjectsAction = {
    type: typeof FETCH_PROJECTS,
    payload: FetchProjectsQuery,
    callback?: Function
}

export type SetFolders = {
    type: typeof SET_FOLDERS,
    payload: Folder[]
}
export type SetProjects = {
    type: typeof SET_PROJECTS,
    payload: Banner[]
}
export type SetActiveFolder = {
    type: typeof SET_ACTIVE_FOLDER,
    payload: number
}

export type AddNewFolderAction = {
    type: typeof ADD_NEW_FOLDER,
    payload: {
        name: string,
        parent?: number,
        type: string,
        product_id: number
    },
    callback?: Function
}

export type FetchFoldersAction = {
    type: typeof FETCH_FOLDERS,
    payload: {
        parent?: number[],
        type: string,
        product_id: number
    },
    callback?: Function
}

export type AddFolder = {
    type: typeof ADD_FOLDER,
    payload: Folder[]
}

export type AddSelectedFolder = {
    type: typeof ADD_SECLECTED_FOLDER,
    payload: number[]
}

export type SetSelectedFolder = {
    type: typeof SET_SELECTED_FOLDER,
    payload: number[]
}

export type MoveFolderAction = {
    type: typeof MOVE_FOLDER,
    payload: {
        selectedFolders: number[],
        destinationFolderId: number,
        product_id?: number
    },
    callback?: Function
}
export type UpdateFolderAction = {
    type: typeof UPDATE_FOLDER,
    payload: Folder,
    callback?: Function
}
export type DeleteFolderAction = {
    type: typeof DELETE_FOLDER,
    payload: number,
    callback?: Function
}

export type SetBannerView = {
    type: typeof SET_BANNER_VIEW,
    payload: boolean
}

export type SetBannerData = {
    type: typeof SET_BANNER_DATA,
    payload: Project | null
}

export type FetchProjectAction = {
    type: typeof FETCH_PROJECT,
    payload: {
        id: number,
        editor_mode: boolean
    },
    callback?: Function
}

export type SetSelectedProject = {
    type: typeof SET_SELECTED_PROJECTS,
    payload: number[]
}

export type DeleteProjects = {
    type: typeof DELETE_PROJECTS,
    payload: number[],
    callback?: Function
}

// export type SetAlertPopUpProps = {
//     type: typeof SET_ALERT_POPUP_PROPS;
//     payload: {
//         title: string,
//         text: string,
//         submittext: string,
//         canceltext: string,
//         onSubmit?: Function,
//         onCancel?: Function
//     };
// };

export type MoveProjects = {
    type: typeof MOVE_PROJECTS,
    payload: {
        selectedProjects: number[],
        destinationFolderId: number,
        product_id: number
    },
    callback?: Function
}

export type FetchFiles = {
    type: typeof FETCH_FILES,
    payload: Banner[]
}

export type FetchFilesAction = {
    type: typeof FETCH_FILES,
    payload: {
        page: number,
        page_size: number,
        product_id: number,
        type: string,
        folder?: number
    },
    callback?: Function
}

export type MoveFiles = {
    type: typeof MOVE_FILES,
    payload: {
        selectedFiles: number[],
        destinationFolderId: number,
        product_id: number
    },
    callback?: Function
}

export type DeleteFiles = {
    type: typeof DELETE_FILES,
    payload: number[],
    callback?: Function
}

export type AddProjectToTemplate = {
    type: typeof ADD_PROJECT_TO_TEMPLATE,
    payload: {
        id: number,
        title: string
    },
    callback?: Function
}

export type UploadLibraryFile = {
    type: typeof UPLOAD_LIBRARY_FILE,
    payload: {
        file: any,
        type: string,
        product_id: string,
        folder?: number
    },
    callback?: Function
}

export type FetchLibraryFile = {
    type: typeof FETCH_LIBRARY_FILE,
    payload: {
        project_id: number[],
    },
    callback?: Function
}

export type SetFoldersPageData = {
    type: typeof SET_FOLDERS_PAGE_DATA,
    payload: any
}

export type SetFolderLoader = {
    type: typeof SET_FOLDER_LOADER,
    payload: boolean
}

export type FetchHyperProjects = {
    type: typeof FETCH_HYPER_PROJECTS,
    payload: {
        hpproject_ids?: number[],
        folder?: number,
        page: number,
        page_size: number,
        product_id: number,
        created_at_start?: string,
        created_at_end?: string
        approved_at_start?: string,
        approved_at_end?: string,
        statuses?: string[],
        search?: string,
        created_by?: string[],
        approved_by?: string[],
        tags?: string[],
        order_by?: string
    },
    callback?: Function
}

export type FetchAllFiles = {
    type: typeof FETCH_ALL_FILES,
    payload: {
        type: string;
        resource_type?: string;
        folder?: number;
        page: number;
        page_size?: number;
    },
    callback?: Function
}

export type SetActiveFolderCreate = {
    type: typeof SET_ACTIVE_FOLDER_CREATE,
    payload: { folder: number }
}

export type DuplicateProject = {
    type: typeof DUPLICATE_PROJECT,
    payload: {
        id: number
    },
    callback?: Function
}

export type SetProjectLoader = {
    type: typeof SET_PROJECT_LOADER,
    payload: boolean
}

export type FetchTags = {
    type: typeof FETCH_TAGS,
    payload: {
        type?: string;
        page: number;
        page_size?: number;
        folder?: number;
    },
    callback?: Function
}


export type SetFilter = {
    type: typeof SET_FILTER,
    payload: any
}

export type GenerateShareableLink = {
    type: typeof GENERATE_SHAREABLE_LINK,
    payload: {
        project_id: number;
        vleId_flag:boolean,
        password_flag: boolean;
        expiration_date: string;
        projectType: string;
        shareable_type: String,
    },
    callback?: Function;
}

export type FetchProjectFromShareableLink = {
    type: typeof FETCH_PROJECT_FROM_SHAREABLE_LINK,
    payload: {
        password?: string;
        shareable_link: string;
        projectType: string;
    },
    callback?: Function;
}

export type DeleteShareableLink = {
    type: typeof DELETE_SHAREABLE_LINK,
    payload: {
        projectId: number;
        projectType: string;
        shareable_type: string;
    },
    callback?: Function;
}

export type GetShareableLink = {
    type: typeof GET_SHAREABLE_LINK,
    payload: {
        project_id: number;
        project_type: string;
        shareable_type: string;
    },
    callback?: Function;
}

export type AddOrDeleteTag = {
    type: typeof ADD_OR_DELETE_TAG,
    payload: {
        entity_id: number;
        entity_type: 'project' | 'hpproject',
        name: string;
        type: 'add' | 'delete'
    },
    callback?: Function
}

export type SearchTags = {
    type: typeof SEARCH_TAGS,
    payload: {
        search: string;
        entityType?: string;
    },
    callback?: Function
}

export type GetTokenFromShareableLink = {
    type: typeof GET_TOKEN_FROM_SHAREABLE_LINK,
    payload: {
        hash_code: string;
        project_type: string;
        password?: string;
        vle_code?:string;
    },
    callback?: Function
}

export type FileToProject = {
    type: typeof FILE_TO_PROJECT,
    payload: {
        file: any;
        type: string;
        folder?: number;
        cancelToken: any
    },
    callback?: Function;
    config?: any;
}

export type UpdateFileToProject = {
    type: typeof UPDATE_FILE_TO_PROJECT,
    payload: {
        project_id: number;
        file: any;
    },
    callback?: Function;
}
export type FileUploader = {
    type: typeof FILE_UPLOADER,
    payload: {
        file_name: any;
        cancelToken: any
    },
    callback?: Function;
}

export type UpdateVariant = {
    type: typeof UPDATE_VARIANT,
    payload: {
        project_id: number;
        file: any;
        project_size: number;
    },
    callback?: Function;
}

export type CreateVariant = {
    type: typeof CREATE_VARIANT,
    payload: {
        project_id: number;
        file: any;
    },
    callback?: Function;
}

export type DeleteVariant = {
    type: typeof DELETE_VARIANT,
    payload: {
        project_id: number;
        project_size: number[];
    },
    callback?: Function;
}

export type CancelFileToProject = {
    type: typeof CANCEL_FILE_TO_PROJECT
}

export type UploadToS3 = {
    type: typeof UPLOAD_TO_S3,
    payload: {
        file_url: any;
        file: any;
        cancelToken: any
    },
    callback?: Function;
    config?: any;
}

export type ProjectUpload = {
    type: typeof PROJECT_UPLOAD,
    payload: {
        title: string,
        type: string,
        file_type: string,
        template?: any,
        folder?: number,
        rendered_file: string,
        sizes?: BannerSize[],
        psdUrl?: string,
        projectJson?: any,
        cancelToken: any
    },
    callback?: Function;
}

export type SetUploadedBanners = {
    type: typeof SET_UPLOADED_BANNERS,
    payload: Banner[]
}


export type SetFileToProjectQueue = {
    type: typeof SET_FILE_TO_PROJECT_QUEUE,
    payload: any
}


export type SetFolderBreadcrumb = {
    type: typeof SET_FOLDER_BREADCRUMB,
    payload: Folder[]
}

export type CreateFolderSutructure = {
    type: typeof CREATE_FOLDER_STRUCTURE,
    payload: {
        structure: any;
        projectType: string;
        parent_id?: number;
    },
    callback?: Function;
}

export type GetQuickViewBucket = {
    type: typeof GET_QUICK_VIEW_BUCKET,
    payload: {
        role: string;
    },
    callback?: Function
}

export type SetUploadedFolder = {
    type: typeof SET_UPLOADED_FOLDER,
    payload: Folder[]
}

export type SetUploadFileList = {
    type: typeof SET_UPLOAD_FILE_LIST,
    payload: any[]
}

export type SetCurrentFolderFile = {
    type: typeof SET_CURRENT_FOLDER_FILE,
    payload: number
}

export type SetFolderContentCount = {
    type: typeof SET_FOLDER_CONTENT_COUNT,
    payload: {}
}

export type SetTempFolderContentCount = {
    type: typeof SET_TEMP_FOLDER_CONTENT_COUNT,
    payload: {}
}
export type UpdateProjectDescription = {
    type: typeof UPDATE_PROJECT_DESCRIPTION,
    payload: {
        project_id: number;
        body: any;
    },
    callback?: Function
}

export type SearchMeta = {
    type: typeof SEARCH_META,
    payload: {
        search: string;
        entityType: string;
    },
    callback?: Function
}

export type AddMeta = {
    type: typeof ADD_META,
    payload: {
        type: string;
        project_size_id: any,
        data: string;
    },
    callback?: Function
}

export type DeleteMeta = {
    type: typeof DELETE_META,
    payload: {
        type: string;
        id: number;
        project_size_id: number;
    },
    callback?: Function
}

export type FetchDesignMeta = {
    type: typeof FETCH_DESIGN_META,
    payload: {
        project_size_id: number
    },
    callback?: Function
}