import React, { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Img from '../../ImageLoader/ImageLoader';
import Logo from '../../../assets/svg/pyxisLogo.svg';
import './index.scss';
import { AuthState, FolderState, HyperProjectFoldersState } from '../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { WEBSOCKET_ENDPOINT } from '../../../utilities/paths';
import { setProject } from '../../../store/actions';
import useWebSocket from '../../shared/hooks/useWebSocket';

const PublicNavBar: FC = () => {
    const { bannerData } = useSelector((state: { folders: FolderState }) => (state.folders));
    const hyperProjectData = useSelector((state: { hyperProject: HyperProjectFoldersState }) => state.hyperProject.hyperProjectData);
    const token = useSelector((state: { authentication: AuthState }) => state.authentication.token);
    const publicLinkUser = useSelector((state: { publicLink: any }) => state.publicLink);
    const params: any = useParams();
    const dispatch = useDispatch();
    const banner = (params['projectType'] && (params['projectType'] === 'project' ? bannerData : hyperProjectData)?.id);
    const shouldSocketEnable = params['projectType'] && params['projectLinkKey'] && !!bannerData;
    sessionStorage.setItem('folder_name',JSON.stringify(publicLinkUser.folderName));
    // const [client, setClientActions] = useWebSocket(`${WEBSOCKET_ENDPOINT}/realtime-updates/entity/${banner}/updates?token=${token}&type=project`, shouldSocketEnable);
    
    // useEffect(() => {
    //     if (client) {
    //         setClientActions((val: any) => ({
    //             ...val,
    //             onError: (err: any) => { console.error('error', 'Not able to connect!') },
    //             onRecieve: (data: any) => {
    //                 if (data.render_status !== "INPROGRESS")
    //                     dispatch(setProject({ ...bannerData, ...data }));
    //             }
    //         }))
    //     }
    //     return ()=>{
    //         if(client){ client.close() }
    //     }
    // }, [bannerData])

    return <div className="cocreate-navbar noselect">
        <div className="flex" style={{ width: '100%' }}>
            <Link className="logo" to='/'>
                <img src={Logo} />
            </Link>
            {publicLinkUser && <div className="detail" style={{ margin: "auto" }}>
                {publicLinkUser.name && <h3 className="title">Shared By : {publicLinkUser.name}</h3>}
                {publicLinkUser.folderName && <h4 className="subtitle">Name : {publicLinkUser.folderName}</h4>}
            </div>}
        </div>
    </div>
}

export default PublicNavBar;